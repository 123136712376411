// source: cse.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */

var jspb = require("google-protobuf");
var goog = jspb;
var global = Function("return this")();

goog.exportSymbol("proto.event.Age", null, global);
goog.exportSymbol("proto.event.AgeGender", null, global);
goog.exportSymbol("proto.event.AgeGenders", null, global);
goog.exportSymbol("proto.event.Ages", null, global);
goog.exportSymbol("proto.event.DateEvent", null, global);
goog.exportSymbol("proto.event.DateEvents", null, global);
goog.exportSymbol("proto.event.EventsRequest", null, global);
goog.exportSymbol("proto.event.EventsResponse", null, global);
goog.exportSymbol("proto.event.Gender", null, global);
goog.exportSymbol("proto.event.Genders", null, global);
goog.exportSymbol("proto.event.HourEvent", null, global);
goog.exportSymbol("proto.event.HourEvents", null, global);
goog.exportSymbol("proto.event.PynDetails", null, global);
goog.exportSymbol("proto.event.Totals", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.EventsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.EventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.EventsRequest.displayName = "proto.event.EventsRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.EventsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.EventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.EventsResponse.displayName = "proto.event.EventsResponse";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.Totals = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.Totals, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.Totals.displayName = "proto.event.Totals";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.Age = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.Age, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.Age.displayName = "proto.event.Age";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.Ages = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.event.Ages.repeatedFields_,
    null
  );
};
goog.inherits(proto.event.Ages, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.Ages.displayName = "proto.event.Ages";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.Gender = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.Gender, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.Gender.displayName = "proto.event.Gender";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.Genders = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.event.Genders.repeatedFields_,
    null
  );
};
goog.inherits(proto.event.Genders, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.Genders.displayName = "proto.event.Genders";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.AgeGender = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.AgeGender, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.AgeGender.displayName = "proto.event.AgeGender";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.AgeGenders = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.event.AgeGenders.repeatedFields_,
    null
  );
};
goog.inherits(proto.event.AgeGenders, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.AgeGenders.displayName = "proto.event.AgeGenders";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.HourEvent = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.HourEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.HourEvent.displayName = "proto.event.HourEvent";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.HourEvents = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.event.HourEvents.repeatedFields_,
    null
  );
};
goog.inherits(proto.event.HourEvents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.HourEvents.displayName = "proto.event.HourEvents";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.DateEvent = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.DateEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.DateEvent.displayName = "proto.event.DateEvent";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.DateEvents = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.event.DateEvents.repeatedFields_,
    null
  );
};
goog.inherits(proto.event.DateEvents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.DateEvents.displayName = "proto.event.DateEvents";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.PynDetails = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.PynDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.PynDetails.displayName = "proto.event.PynDetails";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.event.EventsRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.event.EventsRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.event.EventsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.event.EventsRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        type: jspb.Message.getFieldWithDefault(msg, 1, ""),
        interval: jspb.Message.getFieldWithDefault(msg, 2, 0),
        from: jspb.Message.getFieldWithDefault(msg, 3, ""),
        to: jspb.Message.getFieldWithDefault(msg, 4, ""),
        company: jspb.Message.getFieldWithDefault(msg, 5, ""),
        pyn: jspb.Message.getFieldWithDefault(msg, 6, ""),
        city: jspb.Message.getFieldWithDefault(msg, 7, ""),
        state: jspb.Message.getFieldWithDefault(msg, 8, ""),
        eventType: jspb.Message.getFieldWithDefault(msg, 9, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.EventsRequest}
 */
proto.event.EventsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.EventsRequest();
  return proto.event.EventsRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.EventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.EventsRequest}
 */
proto.event.EventsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setType(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setInterval(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setFrom(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setTo(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setCompany(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setPyn(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setCity(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setState(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setEventType(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.EventsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.event.EventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.EventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.EventsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getInterval();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getTo();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getCompany();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getPyn();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(8, f);
  }
  f = message.getEventType();
  if (f.length > 0) {
    writer.writeString(9, f);
  }
};

/**
 * optional string type = 1;
 * @return {string}
 */
proto.event.EventsRequest.prototype.getType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.event.EventsRequest} returns this
 */
proto.event.EventsRequest.prototype.setType = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional int32 interval = 2;
 * @return {number}
 */
proto.event.EventsRequest.prototype.getInterval = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.EventsRequest} returns this
 */
proto.event.EventsRequest.prototype.setInterval = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional string from = 3;
 * @return {string}
 */
proto.event.EventsRequest.prototype.getFrom = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.event.EventsRequest} returns this
 */
proto.event.EventsRequest.prototype.setFrom = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string to = 4;
 * @return {string}
 */
proto.event.EventsRequest.prototype.getTo = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.event.EventsRequest} returns this
 */
proto.event.EventsRequest.prototype.setTo = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string company = 5;
 * @return {string}
 */
proto.event.EventsRequest.prototype.getCompany = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.event.EventsRequest} returns this
 */
proto.event.EventsRequest.prototype.setCompany = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional string pyn = 6;
 * @return {string}
 */
proto.event.EventsRequest.prototype.getPyn = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/**
 * @param {string} value
 * @return {!proto.event.EventsRequest} returns this
 */
proto.event.EventsRequest.prototype.setPyn = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string city = 7;
 * @return {string}
 */
proto.event.EventsRequest.prototype.getCity = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};

/**
 * @param {string} value
 * @return {!proto.event.EventsRequest} returns this
 */
proto.event.EventsRequest.prototype.setCity = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional string state = 8;
 * @return {string}
 */
proto.event.EventsRequest.prototype.getState = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};

/**
 * @param {string} value
 * @return {!proto.event.EventsRequest} returns this
 */
proto.event.EventsRequest.prototype.setState = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * optional string event_type = 9;
 * @return {string}
 */
proto.event.EventsRequest.prototype.getEventType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};

/**
 * @param {string} value
 * @return {!proto.event.EventsRequest} returns this
 */
proto.event.EventsRequest.prototype.setEventType = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.event.EventsResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.event.EventsResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.event.EventsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.event.EventsResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        ages:
          (f = msg.getAges()) && proto.event.Ages.toObject(includeInstance, f),
        genders:
          (f = msg.getGenders()) &&
          proto.event.Genders.toObject(includeInstance, f),
        agegenders:
          (f = msg.getAgegenders()) &&
          proto.event.AgeGenders.toObject(includeInstance, f),
        hourevents:
          (f = msg.getHourevents()) &&
          proto.event.HourEvents.toObject(includeInstance, f),
        dateevents:
          (f = msg.getDateevents()) &&
          proto.event.DateEvents.toObject(includeInstance, f),
        pyndetails:
          (f = msg.getPyndetails()) &&
          proto.event.PynDetails.toObject(includeInstance, f),
        key: jspb.Message.getFieldWithDefault(msg, 7, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.EventsResponse}
 */
proto.event.EventsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.EventsResponse();
  return proto.event.EventsResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.EventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.EventsResponse}
 */
proto.event.EventsResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.event.Ages();
        reader.readMessage(value, proto.event.Ages.deserializeBinaryFromReader);
        msg.setAges(value);
        break;
      case 2:
        var value = new proto.event.Genders();
        reader.readMessage(
          value,
          proto.event.Genders.deserializeBinaryFromReader
        );
        msg.setGenders(value);
        break;
      case 3:
        var value = new proto.event.AgeGenders();
        reader.readMessage(
          value,
          proto.event.AgeGenders.deserializeBinaryFromReader
        );
        msg.setAgegenders(value);
        break;
      case 4:
        var value = new proto.event.HourEvents();
        reader.readMessage(
          value,
          proto.event.HourEvents.deserializeBinaryFromReader
        );
        msg.setHourevents(value);
        break;
      case 5:
        var value = new proto.event.DateEvents();
        reader.readMessage(
          value,
          proto.event.DateEvents.deserializeBinaryFromReader
        );
        msg.setDateevents(value);
        break;
      case 6:
        var value = new proto.event.PynDetails();
        reader.readMessage(
          value,
          proto.event.PynDetails.deserializeBinaryFromReader
        );
        msg.setPyndetails(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.EventsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.event.EventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.EventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.EventsResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getAges();
  if (f != null) {
    writer.writeMessage(1, f, proto.event.Ages.serializeBinaryToWriter);
  }
  f = message.getGenders();
  if (f != null) {
    writer.writeMessage(2, f, proto.event.Genders.serializeBinaryToWriter);
  }
  f = message.getAgegenders();
  if (f != null) {
    writer.writeMessage(3, f, proto.event.AgeGenders.serializeBinaryToWriter);
  }
  f = message.getHourevents();
  if (f != null) {
    writer.writeMessage(4, f, proto.event.HourEvents.serializeBinaryToWriter);
  }
  f = message.getDateevents();
  if (f != null) {
    writer.writeMessage(5, f, proto.event.DateEvents.serializeBinaryToWriter);
  }
  f = message.getPyndetails();
  if (f != null) {
    writer.writeMessage(6, f, proto.event.PynDetails.serializeBinaryToWriter);
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
};

/**
 * optional Ages ages = 1;
 * @return {?proto.event.Ages}
 */
proto.event.EventsResponse.prototype.getAges = function () {
  return /** @type{?proto.event.Ages} */ (
    jspb.Message.getWrapperField(this, proto.event.Ages, 1)
  );
};

/**
 * @param {?proto.event.Ages|undefined} value
 * @return {!proto.event.EventsResponse} returns this
 */
proto.event.EventsResponse.prototype.setAges = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.event.EventsResponse} returns this
 */
proto.event.EventsResponse.prototype.clearAges = function () {
  return this.setAges(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.EventsResponse.prototype.hasAges = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional Genders genders = 2;
 * @return {?proto.event.Genders}
 */
proto.event.EventsResponse.prototype.getGenders = function () {
  return /** @type{?proto.event.Genders} */ (
    jspb.Message.getWrapperField(this, proto.event.Genders, 2)
  );
};

/**
 * @param {?proto.event.Genders|undefined} value
 * @return {!proto.event.EventsResponse} returns this
 */
proto.event.EventsResponse.prototype.setGenders = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.event.EventsResponse} returns this
 */
proto.event.EventsResponse.prototype.clearGenders = function () {
  return this.setGenders(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.EventsResponse.prototype.hasGenders = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional AgeGenders ageGenders = 3;
 * @return {?proto.event.AgeGenders}
 */
proto.event.EventsResponse.prototype.getAgegenders = function () {
  return /** @type{?proto.event.AgeGenders} */ (
    jspb.Message.getWrapperField(this, proto.event.AgeGenders, 3)
  );
};

/**
 * @param {?proto.event.AgeGenders|undefined} value
 * @return {!proto.event.EventsResponse} returns this
 */
proto.event.EventsResponse.prototype.setAgegenders = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.event.EventsResponse} returns this
 */
proto.event.EventsResponse.prototype.clearAgegenders = function () {
  return this.setAgegenders(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.EventsResponse.prototype.hasAgegenders = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional HourEvents hourEvents = 4;
 * @return {?proto.event.HourEvents}
 */
proto.event.EventsResponse.prototype.getHourevents = function () {
  return /** @type{?proto.event.HourEvents} */ (
    jspb.Message.getWrapperField(this, proto.event.HourEvents, 4)
  );
};

/**
 * @param {?proto.event.HourEvents|undefined} value
 * @return {!proto.event.EventsResponse} returns this
 */
proto.event.EventsResponse.prototype.setHourevents = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.event.EventsResponse} returns this
 */
proto.event.EventsResponse.prototype.clearHourevents = function () {
  return this.setHourevents(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.EventsResponse.prototype.hasHourevents = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional DateEvents dateEvents = 5;
 * @return {?proto.event.DateEvents}
 */
proto.event.EventsResponse.prototype.getDateevents = function () {
  return /** @type{?proto.event.DateEvents} */ (
    jspb.Message.getWrapperField(this, proto.event.DateEvents, 5)
  );
};

/**
 * @param {?proto.event.DateEvents|undefined} value
 * @return {!proto.event.EventsResponse} returns this
 */
proto.event.EventsResponse.prototype.setDateevents = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.event.EventsResponse} returns this
 */
proto.event.EventsResponse.prototype.clearDateevents = function () {
  return this.setDateevents(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.EventsResponse.prototype.hasDateevents = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional PynDetails pynDetails = 6;
 * @return {?proto.event.PynDetails}
 */
proto.event.EventsResponse.prototype.getPyndetails = function () {
  return /** @type{?proto.event.PynDetails} */ (
    jspb.Message.getWrapperField(this, proto.event.PynDetails, 6)
  );
};

/**
 * @param {?proto.event.PynDetails|undefined} value
 * @return {!proto.event.EventsResponse} returns this
 */
proto.event.EventsResponse.prototype.setPyndetails = function (value) {
  return jspb.Message.setWrapperField(this, 6, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.event.EventsResponse} returns this
 */
proto.event.EventsResponse.prototype.clearPyndetails = function () {
  return this.setPyndetails(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.EventsResponse.prototype.hasPyndetails = function () {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * optional string key = 7;
 * @return {string}
 */
proto.event.EventsResponse.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};

/**
 * @param {string} value
 * @return {!proto.event.EventsResponse} returns this
 */
proto.event.EventsResponse.prototype.setKey = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.event.Totals.prototype.toObject = function (opt_includeInstance) {
    return proto.event.Totals.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.event.Totals} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.event.Totals.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        enteringLocation: jspb.Message.getFieldWithDefault(msg, 1, 0),
        drivingBy: jspb.Message.getFieldWithDefault(msg, 2, 0),
        avgVisitMin: jspb.Message.getFieldWithDefault(msg, 3, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.Totals}
 */
proto.event.Totals.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.Totals();
  return proto.event.Totals.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.Totals} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.Totals}
 */
proto.event.Totals.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setEnteringLocation(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setDrivingBy(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setAvgVisitMin(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.Totals.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.event.Totals.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.Totals} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.Totals.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEnteringLocation();
  if (f !== 0) {
    writer.writeInt64(1, f);
  }
  f = message.getDrivingBy();
  if (f !== 0) {
    writer.writeInt64(2, f);
  }
  f = message.getAvgVisitMin();
  if (f !== 0) {
    writer.writeInt64(3, f);
  }
};

/**
 * optional int64 entering_location = 1;
 * @return {number}
 */
proto.event.Totals.prototype.getEnteringLocation = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.Totals} returns this
 */
proto.event.Totals.prototype.setEnteringLocation = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int64 driving_by = 2;
 * @return {number}
 */
proto.event.Totals.prototype.getDrivingBy = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.Totals} returns this
 */
proto.event.Totals.prototype.setDrivingBy = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int64 avg_visit_min = 3;
 * @return {number}
 */
proto.event.Totals.prototype.getAvgVisitMin = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.Totals} returns this
 */
proto.event.Totals.prototype.setAvgVisitMin = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.event.Age.prototype.toObject = function (opt_includeInstance) {
    return proto.event.Age.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.event.Age} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.event.Age.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        cohort1420: jspb.Message.getFieldWithDefault(msg, 1, 0),
        cohort2130: jspb.Message.getFieldWithDefault(msg, 2, 0),
        cohort3140: jspb.Message.getFieldWithDefault(msg, 3, 0),
        cohort4150: jspb.Message.getFieldWithDefault(msg, 4, 0),
        cohort5164: jspb.Message.getFieldWithDefault(msg, 5, 0),
        cohort65: jspb.Message.getFieldWithDefault(msg, 6, 0),
        cohort1420P: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
        cohort2130P: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
        cohort3140P: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
        cohort4150P: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          10,
          0.0
        ),
        cohort5164P: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          11,
          0.0
        ),
        cohort65P: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
        total: jspb.Message.getFieldWithDefault(msg, 13, 0),
        eventType: jspb.Message.getFieldWithDefault(msg, 14, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.Age}
 */
proto.event.Age.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.Age();
  return proto.event.Age.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.Age} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.Age}
 */
proto.event.Age.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCohort1420(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCohort2130(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCohort3140(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCohort4150(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCohort5164(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCohort65(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setCohort1420P(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setCohort2130P(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setCohort3140P(value);
        break;
      case 10:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setCohort4150P(value);
        break;
      case 11:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setCohort5164P(value);
        break;
      case 12:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setCohort65P(value);
        break;
      case 13:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTotal(value);
        break;
      case 14:
        var value = /** @type {string} */ (reader.readString());
        msg.setEventType(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.Age.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.event.Age.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.Age} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.Age.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCohort1420();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getCohort2130();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getCohort3140();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getCohort4150();
  if (f !== 0) {
    writer.writeInt32(4, f);
  }
  f = message.getCohort5164();
  if (f !== 0) {
    writer.writeInt32(5, f);
  }
  f = message.getCohort65();
  if (f !== 0) {
    writer.writeInt32(6, f);
  }
  f = message.getCohort1420P();
  if (f !== 0.0) {
    writer.writeDouble(7, f);
  }
  f = message.getCohort2130P();
  if (f !== 0.0) {
    writer.writeDouble(8, f);
  }
  f = message.getCohort3140P();
  if (f !== 0.0) {
    writer.writeDouble(9, f);
  }
  f = message.getCohort4150P();
  if (f !== 0.0) {
    writer.writeDouble(10, f);
  }
  f = message.getCohort5164P();
  if (f !== 0.0) {
    writer.writeDouble(11, f);
  }
  f = message.getCohort65P();
  if (f !== 0.0) {
    writer.writeDouble(12, f);
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(13, f);
  }
  f = message.getEventType();
  if (f.length > 0) {
    writer.writeString(14, f);
  }
};

/**
 * optional int32 cohort_1420 = 1;
 * @return {number}
 */
proto.event.Age.prototype.getCohort1420 = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.Age} returns this
 */
proto.event.Age.prototype.setCohort1420 = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 cohort_2130 = 2;
 * @return {number}
 */
proto.event.Age.prototype.getCohort2130 = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.Age} returns this
 */
proto.event.Age.prototype.setCohort2130 = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int32 cohort_3140 = 3;
 * @return {number}
 */
proto.event.Age.prototype.getCohort3140 = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.Age} returns this
 */
proto.event.Age.prototype.setCohort3140 = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional int32 cohort_4150 = 4;
 * @return {number}
 */
proto.event.Age.prototype.getCohort4150 = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.Age} returns this
 */
proto.event.Age.prototype.setCohort4150 = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional int32 cohort_5164 = 5;
 * @return {number}
 */
proto.event.Age.prototype.getCohort5164 = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.Age} returns this
 */
proto.event.Age.prototype.setCohort5164 = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional int32 cohort_65 = 6;
 * @return {number}
 */
proto.event.Age.prototype.getCohort65 = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.Age} returns this
 */
proto.event.Age.prototype.setCohort65 = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * optional double cohort_1420_p = 7;
 * @return {number}
 */
proto.event.Age.prototype.getCohort1420P = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.event.Age} returns this
 */
proto.event.Age.prototype.setCohort1420P = function (value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};

/**
 * optional double cohort_2130_p = 8;
 * @return {number}
 */
proto.event.Age.prototype.getCohort2130P = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.event.Age} returns this
 */
proto.event.Age.prototype.setCohort2130P = function (value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};

/**
 * optional double cohort_3140_p = 9;
 * @return {number}
 */
proto.event.Age.prototype.getCohort3140P = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.event.Age} returns this
 */
proto.event.Age.prototype.setCohort3140P = function (value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};

/**
 * optional double cohort_4150_p = 10;
 * @return {number}
 */
proto.event.Age.prototype.getCohort4150P = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.event.Age} returns this
 */
proto.event.Age.prototype.setCohort4150P = function (value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};

/**
 * optional double cohort_5164_p = 11;
 * @return {number}
 */
proto.event.Age.prototype.getCohort5164P = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.event.Age} returns this
 */
proto.event.Age.prototype.setCohort5164P = function (value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};

/**
 * optional double cohort_65_p = 12;
 * @return {number}
 */
proto.event.Age.prototype.getCohort65P = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.event.Age} returns this
 */
proto.event.Age.prototype.setCohort65P = function (value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};

/**
 * optional int32 total = 13;
 * @return {number}
 */
proto.event.Age.prototype.getTotal = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.Age} returns this
 */
proto.event.Age.prototype.setTotal = function (value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};

/**
 * optional string event_type = 14;
 * @return {string}
 */
proto.event.Age.prototype.getEventType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};

/**
 * @param {string} value
 * @return {!proto.event.Age} returns this
 */
proto.event.Age.prototype.setEventType = function (value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.event.Ages.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.event.Ages.prototype.toObject = function (opt_includeInstance) {
    return proto.event.Ages.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.event.Ages} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.event.Ages.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        agesList: jspb.Message.toObjectList(
          msg.getAgesList(),
          proto.event.Age.toObject,
          includeInstance
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.Ages}
 */
proto.event.Ages.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.Ages();
  return proto.event.Ages.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.Ages} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.Ages}
 */
proto.event.Ages.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.event.Age();
        reader.readMessage(value, proto.event.Age.deserializeBinaryFromReader);
        msg.addAges(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.Ages.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.event.Ages.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.Ages} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.Ages.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, proto.event.Age.serializeBinaryToWriter);
  }
};

/**
 * repeated Age ages = 1;
 * @return {!Array<!proto.event.Age>}
 */
proto.event.Ages.prototype.getAgesList = function () {
  return /** @type{!Array<!proto.event.Age>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.event.Age, 1)
  );
};

/**
 * @param {!Array<!proto.event.Age>} value
 * @return {!proto.event.Ages} returns this
 */
proto.event.Ages.prototype.setAgesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.event.Age=} opt_value
 * @param {number=} opt_index
 * @return {!proto.event.Age}
 */
proto.event.Ages.prototype.addAges = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.event.Age,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.event.Ages} returns this
 */
proto.event.Ages.prototype.clearAgesList = function () {
  return this.setAgesList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.event.Gender.prototype.toObject = function (opt_includeInstance) {
    return proto.event.Gender.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.event.Gender} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.event.Gender.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        female: jspb.Message.getFieldWithDefault(msg, 1, 0),
        male: jspb.Message.getFieldWithDefault(msg, 2, 0),
        other: jspb.Message.getFieldWithDefault(msg, 3, 0),
        unspec: jspb.Message.getFieldWithDefault(msg, 4, 0),
        femaleP: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
        maleP: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
        otherP: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
        unspecP: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
        eventType: jspb.Message.getFieldWithDefault(msg, 9, ""),
        total: jspb.Message.getFieldWithDefault(msg, 10, 0),
        nonBinary: jspb.Message.getFieldWithDefault(msg, 11, 0),
        nonBinaryP: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.Gender}
 */
proto.event.Gender.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.Gender();
  return proto.event.Gender.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.Gender} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.Gender}
 */
proto.event.Gender.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setFemale(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMale(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setOther(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setUnspec(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setFemaleP(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setMaleP(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setOtherP(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setUnspecP(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setEventType(value);
        break;
      case 10:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTotal(value);
        break;
      case 11:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setNonBinary(value);
        break;
      case 12:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setNonBinaryP(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.Gender.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.event.Gender.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.Gender} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.Gender.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFemale();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getMale();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getOther();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getUnspec();
  if (f !== 0) {
    writer.writeInt32(4, f);
  }
  f = message.getFemaleP();
  if (f !== 0.0) {
    writer.writeDouble(5, f);
  }
  f = message.getMaleP();
  if (f !== 0.0) {
    writer.writeDouble(6, f);
  }
  f = message.getOtherP();
  if (f !== 0.0) {
    writer.writeDouble(7, f);
  }
  f = message.getUnspecP();
  if (f !== 0.0) {
    writer.writeDouble(8, f);
  }
  f = message.getEventType();
  if (f.length > 0) {
    writer.writeString(9, f);
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(10, f);
  }
  f = message.getNonBinary();
  if (f !== 0) {
    writer.writeInt32(11, f);
  }
  f = message.getNonBinaryP();
  if (f !== 0.0) {
    writer.writeDouble(12, f);
  }
};

/**
 * optional int32 female = 1;
 * @return {number}
 */
proto.event.Gender.prototype.getFemale = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.Gender} returns this
 */
proto.event.Gender.prototype.setFemale = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 male = 2;
 * @return {number}
 */
proto.event.Gender.prototype.getMale = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.Gender} returns this
 */
proto.event.Gender.prototype.setMale = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int32 other = 3;
 * @return {number}
 */
proto.event.Gender.prototype.getOther = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.Gender} returns this
 */
proto.event.Gender.prototype.setOther = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional int32 unspec = 4;
 * @return {number}
 */
proto.event.Gender.prototype.getUnspec = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.Gender} returns this
 */
proto.event.Gender.prototype.setUnspec = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional double female_p = 5;
 * @return {number}
 */
proto.event.Gender.prototype.getFemaleP = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.event.Gender} returns this
 */
proto.event.Gender.prototype.setFemaleP = function (value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};

/**
 * optional double male_p = 6;
 * @return {number}
 */
proto.event.Gender.prototype.getMaleP = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.event.Gender} returns this
 */
proto.event.Gender.prototype.setMaleP = function (value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};

/**
 * optional double other_p = 7;
 * @return {number}
 */
proto.event.Gender.prototype.getOtherP = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.event.Gender} returns this
 */
proto.event.Gender.prototype.setOtherP = function (value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};

/**
 * optional double unspec_p = 8;
 * @return {number}
 */
proto.event.Gender.prototype.getUnspecP = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.event.Gender} returns this
 */
proto.event.Gender.prototype.setUnspecP = function (value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};

/**
 * optional string event_type = 9;
 * @return {string}
 */
proto.event.Gender.prototype.getEventType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};

/**
 * @param {string} value
 * @return {!proto.event.Gender} returns this
 */
proto.event.Gender.prototype.setEventType = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};

/**
 * optional int32 total = 10;
 * @return {number}
 */
proto.event.Gender.prototype.getTotal = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.Gender} returns this
 */
proto.event.Gender.prototype.setTotal = function (value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};

/**
 * optional int32 non_binary = 11;
 * @return {number}
 */
proto.event.Gender.prototype.getNonBinary = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.Gender} returns this
 */
proto.event.Gender.prototype.setNonBinary = function (value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};

/**
 * optional double non_binary_p = 12;
 * @return {number}
 */
proto.event.Gender.prototype.getNonBinaryP = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.event.Gender} returns this
 */
proto.event.Gender.prototype.setNonBinaryP = function (value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.event.Genders.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.event.Genders.prototype.toObject = function (opt_includeInstance) {
    return proto.event.Genders.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.event.Genders} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.event.Genders.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        gendersList: jspb.Message.toObjectList(
          msg.getGendersList(),
          proto.event.Gender.toObject,
          includeInstance
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.Genders}
 */
proto.event.Genders.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.Genders();
  return proto.event.Genders.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.Genders} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.Genders}
 */
proto.event.Genders.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.event.Gender();
        reader.readMessage(
          value,
          proto.event.Gender.deserializeBinaryFromReader
        );
        msg.addGenders(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.Genders.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.event.Genders.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.Genders} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.Genders.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGendersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.event.Gender.serializeBinaryToWriter
    );
  }
};

/**
 * repeated Gender genders = 1;
 * @return {!Array<!proto.event.Gender>}
 */
proto.event.Genders.prototype.getGendersList = function () {
  return /** @type{!Array<!proto.event.Gender>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.event.Gender, 1)
  );
};

/**
 * @param {!Array<!proto.event.Gender>} value
 * @return {!proto.event.Genders} returns this
 */
proto.event.Genders.prototype.setGendersList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.event.Gender=} opt_value
 * @param {number=} opt_index
 * @return {!proto.event.Gender}
 */
proto.event.Genders.prototype.addGenders = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.event.Gender,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.event.Genders} returns this
 */
proto.event.Genders.prototype.clearGendersList = function () {
  return this.setGendersList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.event.AgeGender.prototype.toObject = function (opt_includeInstance) {
    return proto.event.AgeGender.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.event.AgeGender} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.event.AgeGender.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        gender: jspb.Message.getFieldWithDefault(msg, 1, ""),
        cohort1420: jspb.Message.getFieldWithDefault(msg, 2, 0),
        cohort2130: jspb.Message.getFieldWithDefault(msg, 3, 0),
        cohort3140: jspb.Message.getFieldWithDefault(msg, 4, 0),
        cohort4150: jspb.Message.getFieldWithDefault(msg, 5, 0),
        cohort5164: jspb.Message.getFieldWithDefault(msg, 6, 0),
        cohort65: jspb.Message.getFieldWithDefault(msg, 7, 0),
        cohort1420P: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
        cohort2130P: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
        cohort3140P: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          10,
          0.0
        ),
        cohort4150P: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          11,
          0.0
        ),
        cohort5164P: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          12,
          0.0
        ),
        cohort65P: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
        total: jspb.Message.getFieldWithDefault(msg, 14, 0),
        eventType: jspb.Message.getFieldWithDefault(msg, 15, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.AgeGender}
 */
proto.event.AgeGender.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.AgeGender();
  return proto.event.AgeGender.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.AgeGender} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.AgeGender}
 */
proto.event.AgeGender.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGender(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCohort1420(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCohort2130(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCohort3140(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCohort4150(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCohort5164(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCohort65(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setCohort1420P(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setCohort2130P(value);
        break;
      case 10:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setCohort3140P(value);
        break;
      case 11:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setCohort4150P(value);
        break;
      case 12:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setCohort5164P(value);
        break;
      case 13:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setCohort65P(value);
        break;
      case 14:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTotal(value);
        break;
      case 15:
        var value = /** @type {string} */ (reader.readString());
        msg.setEventType(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.AgeGender.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.event.AgeGender.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.AgeGender} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.AgeGender.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGender();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getCohort1420();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getCohort2130();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getCohort3140();
  if (f !== 0) {
    writer.writeInt32(4, f);
  }
  f = message.getCohort4150();
  if (f !== 0) {
    writer.writeInt32(5, f);
  }
  f = message.getCohort5164();
  if (f !== 0) {
    writer.writeInt32(6, f);
  }
  f = message.getCohort65();
  if (f !== 0) {
    writer.writeInt32(7, f);
  }
  f = message.getCohort1420P();
  if (f !== 0.0) {
    writer.writeDouble(8, f);
  }
  f = message.getCohort2130P();
  if (f !== 0.0) {
    writer.writeDouble(9, f);
  }
  f = message.getCohort3140P();
  if (f !== 0.0) {
    writer.writeDouble(10, f);
  }
  f = message.getCohort4150P();
  if (f !== 0.0) {
    writer.writeDouble(11, f);
  }
  f = message.getCohort5164P();
  if (f !== 0.0) {
    writer.writeDouble(12, f);
  }
  f = message.getCohort65P();
  if (f !== 0.0) {
    writer.writeDouble(13, f);
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(14, f);
  }
  f = message.getEventType();
  if (f.length > 0) {
    writer.writeString(15, f);
  }
};

/**
 * optional string gender = 1;
 * @return {string}
 */
proto.event.AgeGender.prototype.getGender = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.event.AgeGender} returns this
 */
proto.event.AgeGender.prototype.setGender = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional int32 cohort_1420 = 2;
 * @return {number}
 */
proto.event.AgeGender.prototype.getCohort1420 = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.AgeGender} returns this
 */
proto.event.AgeGender.prototype.setCohort1420 = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int32 cohort_2130 = 3;
 * @return {number}
 */
proto.event.AgeGender.prototype.getCohort2130 = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.AgeGender} returns this
 */
proto.event.AgeGender.prototype.setCohort2130 = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional int32 cohort_3140 = 4;
 * @return {number}
 */
proto.event.AgeGender.prototype.getCohort3140 = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.AgeGender} returns this
 */
proto.event.AgeGender.prototype.setCohort3140 = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional int32 cohort_4150 = 5;
 * @return {number}
 */
proto.event.AgeGender.prototype.getCohort4150 = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.AgeGender} returns this
 */
proto.event.AgeGender.prototype.setCohort4150 = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional int32 cohort_5164 = 6;
 * @return {number}
 */
proto.event.AgeGender.prototype.getCohort5164 = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.AgeGender} returns this
 */
proto.event.AgeGender.prototype.setCohort5164 = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * optional int32 cohort_65 = 7;
 * @return {number}
 */
proto.event.AgeGender.prototype.getCohort65 = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.AgeGender} returns this
 */
proto.event.AgeGender.prototype.setCohort65 = function (value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};

/**
 * optional double cohort_1420_p = 8;
 * @return {number}
 */
proto.event.AgeGender.prototype.getCohort1420P = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.event.AgeGender} returns this
 */
proto.event.AgeGender.prototype.setCohort1420P = function (value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};

/**
 * optional double cohort_2130_p = 9;
 * @return {number}
 */
proto.event.AgeGender.prototype.getCohort2130P = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.event.AgeGender} returns this
 */
proto.event.AgeGender.prototype.setCohort2130P = function (value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};

/**
 * optional double cohort_3140_p = 10;
 * @return {number}
 */
proto.event.AgeGender.prototype.getCohort3140P = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.event.AgeGender} returns this
 */
proto.event.AgeGender.prototype.setCohort3140P = function (value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};

/**
 * optional double cohort_4150_p = 11;
 * @return {number}
 */
proto.event.AgeGender.prototype.getCohort4150P = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.event.AgeGender} returns this
 */
proto.event.AgeGender.prototype.setCohort4150P = function (value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};

/**
 * optional double cohort_5164_p = 12;
 * @return {number}
 */
proto.event.AgeGender.prototype.getCohort5164P = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.event.AgeGender} returns this
 */
proto.event.AgeGender.prototype.setCohort5164P = function (value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};

/**
 * optional double cohort_65_p = 13;
 * @return {number}
 */
proto.event.AgeGender.prototype.getCohort65P = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.event.AgeGender} returns this
 */
proto.event.AgeGender.prototype.setCohort65P = function (value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};

/**
 * optional int32 total = 14;
 * @return {number}
 */
proto.event.AgeGender.prototype.getTotal = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.AgeGender} returns this
 */
proto.event.AgeGender.prototype.setTotal = function (value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};

/**
 * optional string event_type = 15;
 * @return {string}
 */
proto.event.AgeGender.prototype.getEventType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};

/**
 * @param {string} value
 * @return {!proto.event.AgeGender} returns this
 */
proto.event.AgeGender.prototype.setEventType = function (value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.event.AgeGenders.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.event.AgeGenders.prototype.toObject = function (opt_includeInstance) {
    return proto.event.AgeGenders.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.event.AgeGenders} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.event.AgeGenders.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        agegendersList: jspb.Message.toObjectList(
          msg.getAgegendersList(),
          proto.event.AgeGender.toObject,
          includeInstance
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.AgeGenders}
 */
proto.event.AgeGenders.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.AgeGenders();
  return proto.event.AgeGenders.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.AgeGenders} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.AgeGenders}
 */
proto.event.AgeGenders.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.event.AgeGender();
        reader.readMessage(
          value,
          proto.event.AgeGender.deserializeBinaryFromReader
        );
        msg.addAgegenders(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.AgeGenders.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.event.AgeGenders.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.AgeGenders} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.AgeGenders.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAgegendersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.event.AgeGender.serializeBinaryToWriter
    );
  }
};

/**
 * repeated AgeGender ageGenders = 1;
 * @return {!Array<!proto.event.AgeGender>}
 */
proto.event.AgeGenders.prototype.getAgegendersList = function () {
  return /** @type{!Array<!proto.event.AgeGender>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.event.AgeGender, 1)
  );
};

/**
 * @param {!Array<!proto.event.AgeGender>} value
 * @return {!proto.event.AgeGenders} returns this
 */
proto.event.AgeGenders.prototype.setAgegendersList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.event.AgeGender=} opt_value
 * @param {number=} opt_index
 * @return {!proto.event.AgeGender}
 */
proto.event.AgeGenders.prototype.addAgegenders = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.event.AgeGender,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.event.AgeGenders} returns this
 */
proto.event.AgeGenders.prototype.clearAgegendersList = function () {
  return this.setAgegendersList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.event.HourEvent.prototype.toObject = function (opt_includeInstance) {
    return proto.event.HourEvent.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.event.HourEvent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.event.HourEvent.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        total: jspb.Message.getFieldWithDefault(msg, 1, 0),
        hour: jspb.Message.getFieldWithDefault(msg, 2, 0),
        date: jspb.Message.getFieldWithDefault(msg, 3, ""),
        eventType: jspb.Message.getFieldWithDefault(msg, 4, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.HourEvent}
 */
proto.event.HourEvent.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.HourEvent();
  return proto.event.HourEvent.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.HourEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.HourEvent}
 */
proto.event.HourEvent.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTotal(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setHour(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDate(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setEventType(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.HourEvent.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.event.HourEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.HourEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.HourEvent.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getHour();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getEventType();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
};

/**
 * optional int32 total = 1;
 * @return {number}
 */
proto.event.HourEvent.prototype.getTotal = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.HourEvent} returns this
 */
proto.event.HourEvent.prototype.setTotal = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 hour = 2;
 * @return {number}
 */
proto.event.HourEvent.prototype.getHour = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.HourEvent} returns this
 */
proto.event.HourEvent.prototype.setHour = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional string date = 3;
 * @return {string}
 */
proto.event.HourEvent.prototype.getDate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.event.HourEvent} returns this
 */
proto.event.HourEvent.prototype.setDate = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string event_type = 4;
 * @return {string}
 */
proto.event.HourEvent.prototype.getEventType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.event.HourEvent} returns this
 */
proto.event.HourEvent.prototype.setEventType = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.event.HourEvents.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.event.HourEvents.prototype.toObject = function (opt_includeInstance) {
    return proto.event.HourEvents.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.event.HourEvents} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.event.HourEvents.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        houreventsList: jspb.Message.toObjectList(
          msg.getHoureventsList(),
          proto.event.HourEvent.toObject,
          includeInstance
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.HourEvents}
 */
proto.event.HourEvents.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.HourEvents();
  return proto.event.HourEvents.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.HourEvents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.HourEvents}
 */
proto.event.HourEvents.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.event.HourEvent();
        reader.readMessage(
          value,
          proto.event.HourEvent.deserializeBinaryFromReader
        );
        msg.addHourevents(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.HourEvents.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.event.HourEvents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.HourEvents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.HourEvents.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getHoureventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.event.HourEvent.serializeBinaryToWriter
    );
  }
};

/**
 * repeated HourEvent hourEvents = 1;
 * @return {!Array<!proto.event.HourEvent>}
 */
proto.event.HourEvents.prototype.getHoureventsList = function () {
  return /** @type{!Array<!proto.event.HourEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.event.HourEvent, 1)
  );
};

/**
 * @param {!Array<!proto.event.HourEvent>} value
 * @return {!proto.event.HourEvents} returns this
 */
proto.event.HourEvents.prototype.setHoureventsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.event.HourEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.event.HourEvent}
 */
proto.event.HourEvents.prototype.addHourevents = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.event.HourEvent,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.event.HourEvents} returns this
 */
proto.event.HourEvents.prototype.clearHoureventsList = function () {
  return this.setHoureventsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.event.DateEvent.prototype.toObject = function (opt_includeInstance) {
    return proto.event.DateEvent.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.event.DateEvent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.event.DateEvent.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        total: jspb.Message.getFieldWithDefault(msg, 1, 0),
        eventType: jspb.Message.getFieldWithDefault(msg, 2, ""),
        date: jspb.Message.getFieldWithDefault(msg, 3, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.DateEvent}
 */
proto.event.DateEvent.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.DateEvent();
  return proto.event.DateEvent.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.DateEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.DateEvent}
 */
proto.event.DateEvent.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTotal(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setEventType(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.DateEvent.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.event.DateEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.DateEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.DateEvent.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getEventType();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional int32 total = 1;
 * @return {number}
 */
proto.event.DateEvent.prototype.getTotal = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.DateEvent} returns this
 */
proto.event.DateEvent.prototype.setTotal = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string event_type = 2;
 * @return {string}
 */
proto.event.DateEvent.prototype.getEventType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.event.DateEvent} returns this
 */
proto.event.DateEvent.prototype.setEventType = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string date = 3;
 * @return {string}
 */
proto.event.DateEvent.prototype.getDate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.event.DateEvent} returns this
 */
proto.event.DateEvent.prototype.setDate = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.event.DateEvents.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.event.DateEvents.prototype.toObject = function (opt_includeInstance) {
    return proto.event.DateEvents.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.event.DateEvents} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.event.DateEvents.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        dateeventsList: jspb.Message.toObjectList(
          msg.getDateeventsList(),
          proto.event.DateEvent.toObject,
          includeInstance
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.DateEvents}
 */
proto.event.DateEvents.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.DateEvents();
  return proto.event.DateEvents.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.DateEvents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.DateEvents}
 */
proto.event.DateEvents.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.event.DateEvent();
        reader.readMessage(
          value,
          proto.event.DateEvent.deserializeBinaryFromReader
        );
        msg.addDateevents(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.DateEvents.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.event.DateEvents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.DateEvents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.DateEvents.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDateeventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.event.DateEvent.serializeBinaryToWriter
    );
  }
};

/**
 * repeated DateEvent dateEvents = 1;
 * @return {!Array<!proto.event.DateEvent>}
 */
proto.event.DateEvents.prototype.getDateeventsList = function () {
  return /** @type{!Array<!proto.event.DateEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.event.DateEvent, 1)
  );
};

/**
 * @param {!Array<!proto.event.DateEvent>} value
 * @return {!proto.event.DateEvents} returns this
 */
proto.event.DateEvents.prototype.setDateeventsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.event.DateEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.event.DateEvent}
 */
proto.event.DateEvents.prototype.addDateevents = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.event.DateEvent,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.event.DateEvents} returns this
 */
proto.event.DateEvents.prototype.clearDateeventsList = function () {
  return this.setDateeventsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.event.PynDetails.prototype.toObject = function (opt_includeInstance) {
    return proto.event.PynDetails.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.event.PynDetails} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.event.PynDetails.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        colTotal: jspb.Message.getFieldWithDefault(msg, 1, 0),
        actTotal: jspb.Message.getFieldWithDefault(msg, 2, 0),
        colRangeTotal: jspb.Message.getFieldWithDefault(msg, 3, 0),
        actRangeTotal: jspb.Message.getFieldWithDefault(msg, 4, 0),
        colAvgWeekly: jspb.Message.getFieldWithDefault(msg, 5, 0),
        colAvgMonthly: jspb.Message.getFieldWithDefault(msg, 6, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.PynDetails}
 */
proto.event.PynDetails.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.PynDetails();
  return proto.event.PynDetails.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.PynDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.PynDetails}
 */
proto.event.PynDetails.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setColTotal(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setActTotal(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setColRangeTotal(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setActRangeTotal(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setColAvgWeekly(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setColAvgMonthly(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.PynDetails.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.event.PynDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.PynDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.PynDetails.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getColTotal();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getActTotal();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getColRangeTotal();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getActRangeTotal();
  if (f !== 0) {
    writer.writeInt32(4, f);
  }
  f = message.getColAvgWeekly();
  if (f !== 0) {
    writer.writeInt32(5, f);
  }
  f = message.getColAvgMonthly();
  if (f !== 0) {
    writer.writeInt32(6, f);
  }
};

/**
 * optional int32 col_total = 1;
 * @return {number}
 */
proto.event.PynDetails.prototype.getColTotal = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.PynDetails} returns this
 */
proto.event.PynDetails.prototype.setColTotal = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 act_total = 2;
 * @return {number}
 */
proto.event.PynDetails.prototype.getActTotal = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.PynDetails} returns this
 */
proto.event.PynDetails.prototype.setActTotal = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int32 col_range_total = 3;
 * @return {number}
 */
proto.event.PynDetails.prototype.getColRangeTotal = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.PynDetails} returns this
 */
proto.event.PynDetails.prototype.setColRangeTotal = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional int32 act_range_total = 4;
 * @return {number}
 */
proto.event.PynDetails.prototype.getActRangeTotal = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.PynDetails} returns this
 */
proto.event.PynDetails.prototype.setActRangeTotal = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional int32 col_avg_weekly = 5;
 * @return {number}
 */
proto.event.PynDetails.prototype.getColAvgWeekly = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.PynDetails} returns this
 */
proto.event.PynDetails.prototype.setColAvgWeekly = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional int32 col_avg_monthly = 6;
 * @return {number}
 */
proto.event.PynDetails.prototype.getColAvgMonthly = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.event.PynDetails} returns this
 */
proto.event.PynDetails.prototype.setColAvgMonthly = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};

goog.object.extend(exports, proto.event);
