import { useContext, useState, useEffect } from "react";
import PageSubHeader from "../common/PageSubHeader";
import CompanySelectionMenu from "../common/CompanySelectionMenu";
import CountryButton from "../common/CountryButton";
import { Container, Box, Typography } from "@mui/material";
import GlobalContext from "../common/Context";
import * as constants from "../../constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoadingWheel from "../common/LoadingWheel";

const CompanySelection = () => {
  const { setCompanyState, companyState, firebaseState } =
    useContext(GlobalContext);
  const tabletView = useMediaQuery("(max-width:1280px) and (min-width:744px)");
  const mobileView = useMediaQuery("(max-width:743px) and (min-width:300px)");
  const menuWidth = tabletView ? "28rem" : mobileView ? "18rem" : "34rem";
  const titleMargin = tabletView ? "-1.5rem" : mobileView ? "-3rem" : "1rem";
  //TABLET STYLES
  const tabletTitleBoxStyle = { width: "85%", mt: titleMargin };
  const pageWidth = mobileView ? "94%" : "90%";

  //WEB APP STYLES
  const containerStyle = { maxWidth: pageWidth };
  const chooseBoxStyle = { marginTop: "2rem" };
  const countryBoxStyle = { display: "flex", mt: "1rem" };
  const cButtonStyle = { width: "3rem", height: "3rem", mr: "0.25rem" };
  const titleBoxStyle = { width: "60%", mt: titleMargin, mb: "2rem" };

  const [activeButton, setActiveButton] = useState("all");
  const [allCompanies, setAllCompanies] = useState();
  const [sortedCompanies, setSortedCompanies] = useState([]);

  useEffect(() => {
    const getAllCompanies = async () => {
      const companies = await firebaseState.getAllCompaniesData();
      setAllCompanies(companies);
    };
    getAllCompanies();
  }, [firebaseState]);

  useEffect(() => {
    if (!allCompanies) return;

    let sorted;
    switch (activeButton) {
      case "CA":
        sorted = allCompanies.filter((company) => company.countryCode === "CA");
        break;
      case "US":
        sorted = allCompanies.filter((company) => company.countryCode === "US");
        break;
      default:
        sorted = allCompanies;
    }
    setSortedCompanies(
      sorted.sort(function (a, b) {
        if (a.companyName < b.companyName) {
          return -1;
        }

        if (a.companyName > b.companyName) {
          return 1;
        }

        return 0;
      })
    );
  }, [activeButton, allCompanies]);

  function changeActiveButton(name) {
    setActiveButton(name);
  }

  return (
    <Container maxWidth="false" sx={containerStyle}>
      <Box
        sx={{
          ...titleBoxStyle,
          ...(tabletView ||
            (mobileView && {
              ...tabletTitleBoxStyle,
            })),
        }}
      >
        <PageSubHeader pageTitle={constants.CS_PAGE_TITLE}></PageSubHeader>
        <Typography variant="body1" color="primary">
          {mobileView === true ? constants.CS_DESC_M : constants.CS_DESC}
        </Typography>
      </Box>
      <Typography variant="body1" color="primary" sx={chooseBoxStyle}>
        {constants.CS_CHOOSE_COUNTRY}
      </Typography>
      {!allCompanies ? (
        <LoadingWheel width={4} />
      ) : (
        <>
          <Box sx={countryBoxStyle}>
            <CountryButton
              sx={cButtonStyle}
              name={"CA"}
              src={"images/canada.png"}
              clickFunction={() => changeActiveButton("CA")}
              active={activeButton}
              radius="12.5%"
            />
            <CountryButton
              sx={cButtonStyle}
              name={"US"}
              src={"images/usa.png"}
              clickFunction={() => changeActiveButton("US")}
              active={activeButton}
              radius="12.5%"
            />
            <CountryButton
              sx={cButtonStyle}
              name={"all"}
              src={"images/globe.png"}
              clickFunction={() => changeActiveButton("all")}
              active={activeButton}
              radius="50%"
            />
          </Box>
          <Box sx={{ maxHeight: "6rem" }}>
            <CompanySelectionMenu
              handleOnChange={setCompanyState}
              name={"CompanySelectionAutocomplete"}
              placeholder={"Choose a Company"}
              selectedValue={companyState}
              width={menuWidth}
              listOptions={sortedCompanies}
            />
          </Box>
        </>
      )}
    </Container>
  );
};

export default CompanySelection;
