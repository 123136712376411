import { useEffect, useRef } from "react";

/* Conditionally render or display children. 
  Prevents page from being unmounted on navigating away.
  - Set render to true to always mount the page even if it isn't being displayed.
  - Set display to the condition for displaying the page.
  - Set unmount to true to unmount the page when it isn't being displayed.
 */
const ConditionalDisplay = ({ render, display, unmount, children }) => {
  const initialized = useRef(false);

  const displayStyle = { display: display ? null : "none" };

  useEffect(() => {
    if ((render || display) && !initialized.current) {
      initialized.current = true;
    }
  }, [display, render]);

  return display || render || (initialized.current && !unmount) ? (
    <div style={displayStyle}>{children}</div>
  ) : null;
};

export default ConditionalDisplay;
