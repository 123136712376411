import firebase from "firebase/compat/app"; //v9
import "firebase/compat/auth"; //v9
import axios from "axios";

export const Firebase = () => {
  let auth;
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ prompt: "select_account" });

  const getFirebase = async () => {
    const configObj = await axios.get("/__/firebase/init.json");
    firebase.initializeApp(configObj.data);
    auth = firebase.auth();
  };

  const callFn = async (fn, data = {}) => {
    const token = await auth?.currentUser?.getIdToken();
    const headers = {
      "Content-Type": "application/json",
    };
    token && (headers.Authorization = `Bearer ${token}`);

    const res = await fetch(`${window.location.origin}/fns/${fn}`, {
      headers,
      method: "post",
      body: JSON.stringify({ data }),
    });
    if (res.status >= 200 && res.status <= 299) {
      const json = await res.json();
      return json;
    }
    throw new Error(res.statusText);
  };

  const doSignInWithEmailAndPassword = (email, password) =>
    auth.signInWithEmailAndPassword(email, password);

  const signInWithGoogle = () => auth.signInWithPopup(provider);

  const doSignOut = () => auth.signOut();

  const getProfile = async (email) => {
    const { result } = await callFn("profiles-getProfileByEmail", {
      email,
    });
    return result;
  }

  const getCompanyById = async (id) => {
    const { result } = await callFn("companies-getCompanyById", { id });
    return result;
  };

  const getStatesByCompany = async (companyId) => {
    const { result } = await callFn("pyns-getStatesByCompany", {
      companyId,
    });

    return result;
  };

  const getCitiesByCompanyState = async (companyId, state) => {
    const { result } = await callFn("pyns-getCitiesByCompanyState", {
      companyId,
      state,
    });
    return result;
  };

  const getPynsByCompany = async (companyId, state, city) => {
    const { result } = await callFn("pyns-getPynsByCompany", {
      companyId,
      state,
      city,
    });
    return result;
  };

  const getAllCompaniesData = async () => {
    const { result } = await callFn("companies-getAllCompaniesData", {});
    return result;
  };

  return {
    firebase,
    getFirebase,
    doSignInWithEmailAndPassword,
    doSignOut,
    signInWithGoogle,
    getProfile,
    getCompanyById,
    getStatesByCompany,
    getCitiesByCompanyState,
    getPynsByCompany,
    getAllCompaniesData,
  };
};
