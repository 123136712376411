import { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Radio,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import useMediaQuery from "@mui/material/useMediaQuery";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const SingleCheckSelect = ({
  options,
  dropdownTitle,
  onUpdate,
  widthOverride,
  marginOverride,
  mod,
  inputLabel,
}) => {
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const mobileView = useMediaQuery("(max-width:743px) and (min-width:300px)");
  const tabletView = useMediaQuery("(max-width:1280px) and (min-width:744px)");
  const boxWidth = mobileView | tabletView ? "100%" : "31%";
  const boxPercentStyle =
    mobileView | tabletView
      ? mod
        ? `${marginOverride} 0`
        : "0 0 1rem 0"
      : "2rem 3% 0 0";
  const boxStyle = {
    width: boxWidth,
    height: "3.5rem",
    margin: boxPercentStyle,
  };
  const textStyle = {
    color: "#2e117f",
  };
  const formStyle = {
    width: widthOverride ? widthOverride : "100%",
    height: "75%",
    backgroundColor: "white",
  };
  const selectStyle = {
    height: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "solid 2px #2E117F",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "solid 2px #C31CF3",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": { border: "solid 2px #2E117F" },
    },
  };
  const radioStyle = {
    marginRight: "1rem",
  };

  const [toggled, setToggled] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [scrollOverride, setScrollOverride] = useState(false);

  const handleToggle = (e) => {
    setToggled(e.target.value.dataType);
    onUpdate(e.target.value.dataType);
  };
  const handleClose = () => {
    if (scrollOverride) {
      setScrollOverride(false);
    }
    setIsOpen(false);
  };
  let element = document.getElementById("ddl");

  const handleOpen = () => {
    if (
      element.getBoundingClientRect().bottom < 600 &&
      element.getBoundingClientRect().bottom > 450 &&
      !scrollOverride &&
      !mobileView
    ) {
      window.scrollBy(0, 180);
      setScrollOverride(true);
    }
    setIsOpen(true);
  };

  const handleScroll = () => {
    if (!scrollOverride) {
      setIsOpen(false);
    }
    setScrollOverride(false);
  };

  return (
    <Box sx={boxStyle}>
      <Typography sx={textStyle} variant="body2">
        {dropdownTitle}
      </Typography>
      <FormControl sx={formStyle}>
        <Select
          id="ddl"
          sx={selectStyle}
          displayEmpty
          onChange={handleToggle}
          MenuProps={MenuProps}
          open={isOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          renderValue={(selected) => {
            if (!selected) {
              return <Typography>{inputLabel}</Typography>;
            }
            return selected.dataLabel;
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.dataType} value={option}>
              <Radio
                sx={radioStyle}
                checked={
                  !toggled ? setToggled("all") : toggled === option.dataType
                }
                value={option.dataLabel}
                name="radio-buttons"
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
              />
              <Typography>{option.dataLabel}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
export default SingleCheckSelect;
