// package: event
// file: cse.proto

var cse_pb = require("./cse_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var EventService = (function () {
  function EventService() {}
  EventService.serviceName = "event.EventService";
  return EventService;
}());

EventService.GetEvents = {
  methodName: "GetEvents",
  service: EventService,
  requestStream: false,
  responseStream: true,
  requestType: cse_pb.EventsRequest,
  responseType: cse_pb.EventsResponse
};

EventService.GetTotals = {
  methodName: "GetTotals",
  service: EventService,
  requestStream: false,
  responseStream: false,
  requestType: cse_pb.EventsRequest,
  responseType: cse_pb.Totals
};

exports.EventService = EventService;

function EventServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

EventServiceClient.prototype.getEvents = function getEvents(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(EventService.GetEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

EventServiceClient.prototype.getTotals = function getTotals(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EventService.GetTotals, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.EventServiceClient = EventServiceClient;

