import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const FilterExpansion = ({
  pageTitle,
  pageContents,
  children,
  direction,
  doesWrap,
  dashOverride,
}) => {
  const tabletView = useMediaQuery("(max-width:1280px) and (min-width:744px)");
  const mobileView = useMediaQuery("(max-width:743px) and (min-width:300px)");
  //TABLET STYLES
  const tabletContentStyle = {
    padding: "0 1.5rem 1.5rem 1.5rem",
  };
  const mobilePadding = mobileView | tabletView ? "1rem 0 1rem" : "1rem 0 4rem";

  //WEB APP STYLES
  const accordionStyle =
    dashOverride === true
      ? {
          width: "100%",
          margin: "auto",
          backgroundColor: "#f4f2f8",
          boxShadow: "none",
          "& .MuiAccordionSummary-content": {
            flexDirection: "column",
            backgroundColor: "#f4f2f8",
          },
          "& .MuiAccordionSummary-root": { backgroundColor: "#f4f2f8" },
          "& .MuiAccordionDetails-root": { backgroundColor: "#f4f2f8" },
          "& .MuiPaper-root": {
            backgroundColor: "#f4f2f8",
            boxShadow: "none",
            padding: "0 -1rem",
          },
          "& .MuiPaper-root::before": { backgroundColor: "#f4f2f8" },
          "& .MuiButtonBase-root": { padding: "0" },
        }
      : {
          width: "100%",
          boxShadow: "-5px 5px 6px 1px #E6E6E6",
          backgroundColor: "#fff",
          "& .MuiAccordionSummary-content": { flexDirection: "column" },
        };
  const titleStyle = { width: "100%" };
  const subtitleStyle = {
    width: "100%",
    padding: mobilePadding,
  };
  const contentStyle =
    dashOverride === true
      ? {
          display: "flex",
          flexDirection: direction === "c" ? "column" : "row",
          flexWrap: doesWrap === "true" ? "wrap" : "nowrap",
          justifyContent: "space-between",
          padding: "0 0 2rem 0",
        }
      : {
          display: "flex",
          flexDirection: direction === "c" ? "column" : "row",
          flexWrap: doesWrap === "true" ? "wrap" : "nowrap",
          justifyContent: "flex-start",
          padding: "0 2rem 2rem 2rem",
        };

  return dashOverride === true ? (
    <Accordion disableGutters={true} sx={accordionStyle}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h2" sx={titleStyle}>
          {pageTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={contentStyle}>
        <Typography variant="body2" sx={subtitleStyle}>
          {pageContents}
        </Typography>
        {children}
      </AccordionDetails>
    </Accordion>
  ) : (
    <Accordion disableGutters={true} sx={accordionStyle}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h2" sx={titleStyle}>
          {pageTitle}
        </Typography>
        <Typography variant="body2" sx={titleStyle}>
          {pageContents}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          ...contentStyle,
          ...(tabletView && {
            ...tabletContentStyle,
          }),
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterExpansion;
