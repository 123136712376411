import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import * as constants from "../../../constants";
import SquareIcon from "@mui/icons-material/Square";
import CloseIcon from "@mui/icons-material/Close";

const LegendDialog = ({ isOpen, setIsOpen }) => {
  const outerBoxStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  };
  const innerBoxStyle = {
    display: "flex",
    flexDirection: "column",
  };
  const titleBoxStyle = {
    display: "flex",
    justifyContent: "space-between",
  };
  const handleClick = () => {
    setIsOpen(false);
  };
  const textStyle = {
    mr: "1rem",
  };
  const iconStyle = {
    height: "1rem",
    width: "1rem",
    mr: ".5rem",
  };

  const closeIconStyle = {
    fontSize: "medium",
  };
  const titleStyle = {
    mb: "1rem",
  };
  return (
    <Box>
      <Dialog open={isOpen}>
        <DialogContent>
          <Box sx={titleBoxStyle}>
            <Typography variant="h2" sx={titleStyle}>
              {constants.LEGEND_TITLE}
            </Typography>
            <CloseIcon sx={closeIconStyle} onClick={handleClick} />
          </Box>
          <Box sx={outerBoxStyle}>
            <Box sx={innerBoxStyle}>
              <Typography sx={textStyle}>
                <SquareIcon sx={{ ...iconStyle, color: "#c41bf3" }} />
                {constants.GENDER_WOM}
              </Typography>
              <Typography sx={textStyle}>
                <SquareIcon sx={{ ...iconStyle, color: "#207DF9" }} />
                {constants.GENDER_MAN}
              </Typography>
            </Box>
            <Box sx={innerBoxStyle}>
              <Typography sx={textStyle}>
                <SquareIcon sx={{ ...iconStyle, color: "#2E117F" }} />
                {constants.GENDER_NOT}
              </Typography>
              <Typography sx={textStyle}>
                <SquareIcon sx={{ ...iconStyle, color: "#9B57EC" }} />
                {constants.GENDER_SELF}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default LegendDialog;
