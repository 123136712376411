import { NavLink } from "react-router-dom";
import classes from "./Header.module.css";

const Header = () => {
  return (
    <header className={classes.header}>
      <div className={classes.logo}>
        <NavLink to="/home">
          <img
            alt="Trypscore Logo"
            src={"/images/logo/trypscore_whiteTM.png"}
          />
        </NavLink>
      </div>
    </header>
  );
};

export default Header;
