export const Data = () => {
  const getAges = (data, evt, isPercent) => {
    const labels = ["14-20", "21-30", "31-40", "41-50", "51-64", "65+"];
    let gdata = [];
    if (data && data.length) {
      if (data[1] && evt === "en") {
        data = data[1];
      } else {
        data = data[0];
      }
      isPercent
        ? (gdata = [
            Math.round(data["cohort1420P"]),
            Math.round(data["cohort2130P"]),
            Math.round(data["cohort3140P"]),
            Math.round(data["cohort4150P"]),
            Math.round(data["cohort5164P"]),
            Math.round(data["cohort65P"]),
          ])
        : (gdata = [
            data["cohort1420"],
            data["cohort2130"],
            data["cohort3140"],
            data["cohort4150"],
            data["cohort5164"],
            data["cohort65"],
          ]);
    }
    return {
      gdata,
      labels,
    };
  };
  const getGenders = (data, evt, isPercent) => {
    const labels = [
      "Male (including transgender male)",
      "Female (including transgender female)",
      "Non-Binary",
      "Prefer to self describe",
      "Prefer not to specify",
    ];
    let gdata = [];
    if (data && data.length) {
      if (data[1] && evt === "en") {
        data = data[1];
      } else {
        data = data[0];
      }
      isPercent
        ? (gdata = [
            Math.round(data.maleP),
            Math.round(data.femaleP),
            Math.round(data.nonBinaryP),
            Math.round(data.otherP),
            Math.round(data.unspecP),
          ])
        : (gdata = [
            data.male,
            data.female,
            data.nonBinary,
            data.other,
            data.unspec,
          ]);
    }
    return {
      gdata,
      labels,
    };
  };
  const getAgeGenders = (data, gender, evt, isPercent) => {
    const labels = ["14-20", "21-30", "31-40", "41-50", "51-64", "65+"];
    let gdata = [];
    if (data) {
      if (evt === "en" && data[6]) {
        data = data.filter((x) => x.eventType === "enter_location");
      } else if (evt === "db" && data[6]) {
        data = data.filter((x) => x.eventType === "driving_by");
      }
      let filtered = data.filter((x) => x.gender === gender);
      if (filtered) {
        isPercent
          ? (gdata = [
              Math.round(filtered[0]["cohort1420P"]),
              Math.round(filtered[0]["cohort2130P"]),
              Math.round(filtered[0]["cohort3140P"]),
              Math.round(filtered[0]["cohort4150P"]),
              Math.round(filtered[0]["cohort5164P"]),
              Math.round(filtered[0]["cohort65P"]),
            ])
          : (gdata = [
              filtered[0]["cohort1420"],
              filtered[0]["cohort2130"],
              filtered[0]["cohort3140"],
              filtered[0]["cohort4150"],
              filtered[0]["cohort5164"],
              filtered[0]["cohort65"],
            ]);
      }
    }
    return {
      gdata,
      labels,
    };
  };
  return {
    getAges,
    getGenders,
    getAgeGenders,
  };
};
