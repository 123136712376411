import { useState, useContext, useEffect } from "react";
import GlobalContext from "../Context";
import {
  Box,
  Button,
  ClickAwayListener,
  Link,
  MenuItem,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import * as constants from "../../../constants";
import useMediaQuery from "@mui/material/useMediaQuery";

const Navbar = ({ isLoggedIn, isAdmin }) => {
  const { currentUser, setOptionState, firebaseState, companyState } =
    useContext(GlobalContext);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuOpen = Boolean(menuAnchor);
  const [activeButton, setActiveButton] = useState("dashboard");
  const [openIcon, setOpenIcon] = useState("▼");
  const tabletView = useMediaQuery("(max-width:1280px) and (min-width:744px)");
  const mobileView = useMediaQuery("(max-width:743px) and (min-width:300px)");

  // STYLES HAVE TO BE MOVED TO A SEPERATE FILE
  // MOBILE STYLES
  const mobileButtonStyle = {
    height: "3.5rem",
  };
  const mobileUserButtonStyle = {
    height: "3.5rem",
    width: "25%",
  };

  // TABLET STYLES
  const tabletButtonStyle = {
    height: "3.2rem",
  };

  // WEB APP STYLES
  const containerStyle = {
    display: "flex",
    justifyContent: "space-between",
    pl: "80px",
    flexFlow: "nowrap row",
    width: "100%",
    backgroundColor: "#d7d5dd",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    boxShadow: "0px 2px 8px #bfbcc3",
  };
  const buttonBoxStyle = {
    display: "flex",
    width: "100%",
  };
  const buttonStyle = {
    display: "flex",
    justifyContent: "center",
    width: "25%",
    height: "3.8rem",
    padding: "0.4rem 2rem",
    borderRadius: "0px",
    boxShadow: "inset 2px -0.5px 2px -1.5px #bfbcc3",
    color: "rgb(46, 17, 127)",
    textTransform: "uppercase",
    animation: "none",
    "&: hover": {
      cursor: "pointer",
      backgroundColor: "#9687be",
      color: "#fefffe",
      filter: "drop-shadow(1px 2px 3px #c6c4cb)",
    },
  };
  const dashboardBoxStyle = {
    ...(activeButton === "dashboard" && {
      backgroundColor: "#9687be",
      color: "#fefffe",
    }),
  };
  const selectBoxStyle = {
    ...(activeButton === "selection" && {
      backgroundColor: "#9687be",
      color: "#fefffe",
    }),
  };
  const userIconStyle = {
    marginLeft: "10%",
    borderRadius: "50%",
    height: "80%",
  };
  const popperStyle = {
    width: mobileView ? "25%" : "20%",
    height: "3.8rem",
    boxShadow: "0px 4px 4px #c6c4cb",
  };
  const paperStyle = {
    height: "inherit",
    backgroundColor: "#d7d5dd",
    borderRadius: "0px",
  };
  const menuItemStyle = {
    height: "inherit",
    display: "flex",
    justifyContent: "space-around",
    "&: hover": {
      cursor: "pointer",
      backgroundColor: "#9687be",
      color: "#fefffe",
      filter: "drop-shadow(1px 2px 3px #c6c4cb)",
      "& .MuiTypography-root": { color: "#fefffe" },
      "& .MuiSvgIcon-root": { color: "#fefffe" },
    },
  };
  const linkStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const textStyle = {
    mr: "1rem",
  };
  const iconStyle = {
    fontSize: "inherit",
  };

  const handleNavClick = (type) => {
    setActiveButton(type);
  };

  useEffect(() => {
    setOptionState(activeButton);
  }, [activeButton, setOptionState]);

  useEffect(() => {
    menuOpen ? setOpenIcon("▲") : setOpenIcon("▼");
  }, [menuOpen]);

  const handleMenuClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleLogout = () => {
    setMenuAnchor(null);
    firebaseState.doSignOut();
  };

  return isLoggedIn && isAdmin ? (
    <Box sx={containerStyle}>
      <Box sx={buttonBoxStyle}>
        <Button
          id="dashboard"
          className="dashboard-page"
          activeClassName="a-Dashboard"
          onClick={() => handleNavClick("dashboard")}
          sx={{
            ...buttonStyle,
            ...dashboardBoxStyle,
            ...(tabletView && {
              ...tabletButtonStyle,
            }),
            ...(mobileView && {
              ...mobileButtonStyle,
            }),
          }}
        >
          <Typography variant="h3">{constants.NB_DB}</Typography>
        </Button>
        {currentUser?.permissions?.medidas && (
          <Button
            id="selection"
            className="company-selection-page"
            activeClassName="a-CompanySelection"
            onClick={() => handleNavClick("selection")}
            sx={{
              ...buttonStyle,
              ...selectBoxStyle,
              ...(tabletView && {
                ...tabletButtonStyle,
              }),
              ...(mobileView && {
                ...mobileButtonStyle,
              }),
            }}
          >
            <Typography variant="h3">
              {mobileView ? constants.NB_CS_M : constants.NB_CS}
            </Typography>
          </Button>
        )}
      </Box>
      <Button
        className="user-menu-button"
        onClick={handleMenuClick}
        sx={{
          ...buttonStyle,
          ...(tabletView && {
            ...tabletButtonStyle,
          }),
          ...(mobileView && {
            ...mobileUserButtonStyle,
          }),
        }}
      >
        <Typography variant="h3">
          {currentUser.profile?.name} {openIcon}
        </Typography>
        {companyState?.iconUrl && (
          <Box
            component="img"
            sx={userIconStyle}
            src={companyState?.iconUrl}
            alt="USER"
          />
        )}
      </Button>
      <Popper
        style={{
          ...popperStyle,
          ...(tabletView && {
            ...tabletButtonStyle,
          }),
          ...(mobileView && {
            ...mobileUserButtonStyle,
          }),
        }}
        open={menuOpen}
        anchorEl={menuAnchor}
        placement="bottom-start"
        disablePortal={false}
      >
        <Paper sx={paperStyle}>
          <ClickAwayListener onClickAway={handleMenuClose}>
            <MenuItem onClick={handleLogout} sx={menuItemStyle}>
              <Link
                href={constants.SIGNIN_LINK}
                underline="none"
                sx={linkStyle}
              >
                <Typography
                  sx={textStyle}
                  variant="body2"
                  color="primary"
                  fontWeight="bolder"
                >
                  {constants.NB_LOGOUT}
                </Typography>
                <ExitToAppIcon sx={iconStyle} />
              </Link>
            </MenuItem>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Box>
  ) : isLoggedIn ? (
    <Box sx={containerStyle}>
      <Button
        id="dashboard"
        className="dashboard-page"
        activeClassName="a-Dashboard"
        onClick={() => handleNavClick("dashboard")}
        sx={{
          ...buttonStyle,
          ...dashboardBoxStyle,
          ...(tabletView && {
            ...tabletButtonStyle,
          }),
          ...(mobileView && {
            ...mobileButtonStyle,
          }),
        }}
      >
        <h3>{constants.NB_DB}</h3>
      </Button>
      <Button
        className="user-menu-button"
        onClick={handleMenuClick}
        sx={{
          ...buttonStyle,
          ...(tabletView && {
            ...tabletButtonStyle,
          }),
          ...(mobileView && {
            ...mobileButtonStyle,
          }),
        }}
      >
        <h3>{currentUser.profile?.name}</h3>▼
      </Button>
      <Popper
        style={{
          ...popperStyle,
          ...(tabletView && {
            ...tabletButtonStyle,
          }),
          ...(mobileView && {
            ...mobileUserButtonStyle,
          }),
        }}
        open={menuOpen}
        anchorEl={menuAnchor}
        placement="bottom-start"
        disablePortal={false}
      >
        <Paper sx={paperStyle}>
          <ClickAwayListener onClickAway={handleMenuClose}>
            <MenuItem onClick={handleLogout} sx={menuItemStyle}>
              <Link
                href={constants.SIGNIN_LINK}
                underline="none"
                sx={linkStyle}
              >
                <Typography
                  sx={textStyle}
                  variant="body2"
                  color="primary"
                  fontWeight="bolder"
                >
                  {constants.NB_LOGOUT}
                </Typography>
                <ExitToAppIcon sx={iconStyle} />
              </Link>
            </MenuItem>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Box>
  ) : null;
};

export default Navbar;
