import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import * as constants from "../../../constants";
import useMediaQuery from "@mui/material/useMediaQuery";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const noProv = {
  code: "",
  name: "",
};
const noCity = {
  name: "",
};
const noLocation = {
  address: "",
  city: "",
  companyId: "",
  id: "",
  name: "",
  sate: "",
};

const DropdownCheckbox = ({
  inputLabel,
  type,
  selection,
  setSelection,
  upperVar,
  data,
  disabled,
  emptyTitle,
}) => {
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const mobileView = useMediaQuery("(max-width:743px) and (min-width:300px)");
  const tabletView = useMediaQuery("(max-width:1280px) and (min-width:744px)");
  const boxWidth = mobileView | tabletView ? "100%" : "31%";
  const boxPercentStyle = mobileView | tabletView ? "1rem" : "0";

  const boxStyle = {
    width: boxWidth,
    mb: boxPercentStyle,
    height: "3.5rem",
  };
  const textStyle = {
    color: "#2e117f",
  };
  const formStyle = {
    width: "100%",
    height: "75%",
    backgroundColor: "white",
  };
  const selectStyle = {
    height: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "solid 2px #2E117F",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "solid 2px #C31CF3",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": { border: "solid 2px #2E117F" },
    },
  };
  const renderStyle = {
    display: "flex",
    flexWrap: "wrap",
    gap: 0.5,
  };
  const formControlStyle = {
    width: "100%",
  };
  const promptStyle = {
    color: "#806DAD",
    lineHeight: "inherit",
  };
  const checkStyle = {
    m: "0 1rem",
  };

  //useStates
  const [isOpen, setIsOpen] = useState(false);
  const [scrollOverride, setScrollOverride] = useState(false);
  let element = document.getElementById("ddl");

  const handleClose = () => {
    if (scrollOverride) {
      setScrollOverride(false);
    }
    setIsOpen(false);
  };

  const handleOpen = () => {
    if (
      element.getBoundingClientRect().bottom < 450 &&
      mobileView &&
      !scrollOverride
    ) {
      window.scrollBy(0, 180);
      setScrollOverride(true);
    }
    setIsOpen(true);
  };

  const handleScroll = () => {
    if (!scrollOverride) {
      setIsOpen(false);
    }
    setScrollOverride(false);
  };

  // Event Handlers
  const handleProvChange = (event, state) => {
    event.target.checked
      ? setSelection({
          code: state.code,
          name: state.state,
        })
      : setSelection(noProv);
  };

  const handleCityChange = (event, city) => {
    event.target.checked
      ? setSelection({
          name: city.name,
        })
      : setSelection(noCity);
  };

  const handleLocChange = (event, location) => {
    event.target.checked
      ? setSelection({
          address: location.address,
          city: location.city,
          companyId: location.companyId,
          id: location.id,
          name: location.name,
          sate: location.state,
        })
      : setSelection(noLocation);
  };

  useEffect(() => {
    type === "city" ? setSelection(noCity) : setSelection(noLocation);
  }, [setSelection, type, upperVar]);

  return (
    <Box sx={boxStyle}>
      <Typography sx={textStyle} variant="body2">
        {inputLabel}
      </Typography>
      <FormControl sx={formStyle}>
        <Select
          id="ddl"
          sx={selectStyle}
          disabled={upperVar?.length === 0 || disabled}
          displayEmpty
          value={selection}
          open={isOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          MenuProps={MenuProps}
          renderValue={() => {
            if (!data && upperVar?.length) {
              return (
                <Typography sx={promptStyle} variant="body2">
                  {emptyTitle}
                </Typography>
              );
            }
            if (!selection?.name) {
              return (
                <Typography sx={promptStyle} variant="body2">
                  {constants.DB_DDL_PROMPT}
                </Typography>
              );
            }
            return (
              <Box sx={renderStyle}>
                {type === "prov" ? (
                  // onDelete={handleDelete} deleteIcon={<HighlightOffIcon sx={iconStyle}/>} - this adds the delete button for chips
                  <Chip
                    key={selection.code}
                    label={mobileView ? selection.code : selection.name}
                  />
                ) : type === "city" ? (
                  <Chip key={selection.name} label={selection.name} />
                ) : (
                  <Chip key={selection.id} label={selection.address} />
                )}
              </Box>
            );
          }}
        >
          {!data?.length && (
            <MenuItem>
              <Typography sx={promptStyle} variant="body2">
                No Items Available
              </Typography>
            </MenuItem>
          )}
          {type === "prov"
            ? data?.map((state) => (
                <MenuItem key={state.code}>
                  <FormControlLabel
                    sx={formControlStyle}
                    control={
                      <Checkbox
                        sx={checkStyle}
                        checked={state.code === selection?.code}
                        onChange={(event) => handleProvChange(event, state)}
                      />
                    }
                    label={state.state}
                  />
                </MenuItem>
              ))
            : type === "city"
            ? data?.map((city) => (
                <MenuItem key={city.name}>
                  <FormControlLabel
                    sx={formControlStyle}
                    control={
                      <Checkbox
                        sx={checkStyle}
                        checked={city.name === selection?.name}
                        onChange={(event) => handleCityChange(event, city)}
                      />
                    }
                    label={city.name}
                  />
                </MenuItem>
              ))
            : data?.map((location) => (
                <MenuItem key={location.id}>
                  <FormControlLabel
                    sx={formControlStyle}
                    control={
                      <Checkbox
                        sx={checkStyle}
                        checked={location.id === selection?.id}
                        onChange={(event) => handleLocChange(event, location)}
                      />
                    }
                    label={location.address}
                  />
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropdownCheckbox;
