const LoadingCircle = ({ id }) => {
  return (
    <svg
      viewBox="0 0 1280 1280"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      style={{ enableBackground: "new 0 0 1280 1280" }}
      xmlSpace="preserve"
    >
      <g>
        <linearGradient
          id={id}
          x1="400"
          y1="1280"
          x2="880"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0%"
            style={{ stopColor: "#c6c5fa", stopOpacity: "0" }}
          />
          <stop
            offset="20%"
            style={{ stopColor: "#8380f4", stopOpacity: "0.05" }}
          />
          <stop
            offset="28%"
            style={{ stopColor: "#5954f0", stopOpacity: "0.8" }}
          />
          <stop
            offset="32%"
            style={{ stopColor: "#4944ef", stopOpacity: "0.9" }}
          />
          <stop offset="78%" style={{ stopColor: "#8317c9" }} />
          <stop offset="100%" style={{ stopColor: "#a322f3" }} />
        </linearGradient>
        <path
          style={{ fill: `url(#${id})` }}
          d="M640,1098.21c0-15.78-12.22-28.92-27.97-29.93c-48.07-3.07-94.74-14.07-139.07-32.83
			c-51.1-21.61-97-52.56-136.43-91.99c-39.42-39.42-70.37-85.32-91.99-136.43c-22.37-52.89-33.71-109.09-33.71-167.04
			s11.34-114.15,33.71-167.04c21.61-51.1,52.56-97,91.99-136.43c39.42-39.42,85.32-70.37,136.43-91.99
			c52.89-22.37,109.09-33.71,167.04-33.71s114.15,11.34,167.04,33.71c51.1,21.61,97,52.56,136.43,91.99
			c39.42,39.42,70.37,85.32,91.99,136.43c16.33,38.6,26.77,78.96,31.23,120.48c1.64,15.31,14.45,26.97,29.85,26.97l0,0
			c17.76,0,31.73-15.35,29.85-33.01c-5.05-47.48-16.99-93.65-35.67-137.81c-24.64-58.25-59.91-110.56-104.82-155.48
			c-44.92-44.92-97.23-80.18-155.48-104.82c-60.33-25.52-124.39-38.45-190.42-38.45s-130.09,12.94-190.42,38.45
			c-58.25,24.64-110.56,59.91-155.48,104.82s-80.18,97.23-104.82,155.48c-25.52,60.33-38.45,124.39-38.45,190.42
			s12.94,130.09,38.45,190.42c24.64,58.25,59.91,110.57,104.82,155.48c44.92,44.91,97.23,80.18,155.48,104.82
			c50.52,21.37,103.66,33.92,158.38,37.43C625.29,1129.26,640,1115.58,640,1098.21L640,1098.21L640,1098.21z"
        />
      </g>
    </svg>
  );
};

export default LoadingCircle;
