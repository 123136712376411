import { Box, Button, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DLConfirmModal= ({ text, open, clickHandler }) => {
  const modalBoxStyle = {
    position: "relative",
    top: "50%",
    left: "50%",
    display: "flex",
    flexDirection: "column",
    transform: "translate(-50%, -50%)",
    width: "20%",
    height: "14%",
    bgcolor: "background.paper",
    borderRadius: "5px",
  };
  const buttonStyle = { 
    position: "absolute",
    right: 0,
    p: "4%",
  };
  const iconStyle = { fontSize: "1rem" };
  const textStyle = {
    m: "auto 15%",
    textAlign: "center",
    fontWeight: 900,
  };

  return (
    <Modal open={open}>
      <Box sx={modalBoxStyle}>
        <Button sx={buttonStyle} onClick={clickHandler}>
          <CloseIcon sx={iconStyle} />
        </Button>
        <Typography sx={textStyle} variant="body1" color="primary">
          {text}
        </Typography>
      </Box>
    </Modal>
  );
};

export default DLConfirmModal;
