import { Typography, Box } from "@mui/material";
import { NavLink } from "react-router-dom";
import * as constants from "../../../constants";

const NotFound = () => {
  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    p: 4,
  };
  return (
    <Box sx={boxStyle}>
      <Typography variant="h1">{constants.NOT_FOUND}</Typography>
      <Typography variant="h2">
        <NavLink to="/home">{constants.RETURN_HOME}</NavLink>
      </Typography>
    </Box>
  );
};

export default NotFound;
