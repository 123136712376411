import { Component } from "react";
import { Box, Typography } from "@mui/material";
import ReBox from "../ReBox";
import GlobalContext from "../Context";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { mobileView, tabletView } = this.context;
    const buttonStyle = {
      width: "100%",
      height: "2.5rem",
      display: "flex",
      background: "none",
      backgroundColor: "#1E7DFF",
      justifyContent: "center",
      alignItems: "center",
    };
    const leftStyle = {
      display: "flex",
      flexDirection: "column",
      mr: !(mobileView || tabletView) && "3rem",
      alignItems: (mobileView || tabletView) && "center",
    };
    const descTextStyle = {
      mb: "2.5rem",
    };
    const mainTextStyle = {
      fontSize: "5.5rem",
      mb: "0.5rem",
    };
    const titleTextStyle = {
      mb: "0.5rem",
    };
    const boxStyle = {
      display: "flex",
      justifyContent: "center",
      height: "50rem",
      alignItems: "center",
    };

    if (this.state.hasError) {
      return (
        <Box sx={boxStyle}>
          <Box sx={leftStyle}>
            <Typography color="#C31CF3" variant="h1" sx={mainTextStyle}>
              OOPS!
            </Typography>
            <Typography color="primary" variant="h2" sx={titleTextStyle}>
              Something went wrong.
            </Typography>
            <Typography color="primary" variant="body1" sx={descTextStyle}>
              Please return to the dashboard.
            </Typography>
            <ReBox
              style={buttonStyle}
              button={true}
              clickHandler={this.handleClick}
            >
              <Typography color="#fff" variant="h3">
                Back to Dashboard
              </Typography>
            </ReBox>
          </Box>
          {!(mobileView || tabletView) && (
            <img
              alt="404"
              width="40%"
              height="auto"
              src={"/images/404-graphic.png"}
            />
          )}
        </Box>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.contextType = GlobalContext;

export default ErrorBoundary;
