import { grpc } from "@improbable-eng/grpc-web";
import { EventService } from "../../pb/cse_pb_service.js";
import { EventsRequest } from "../../pb/cse_pb.js";

export const Grpc = (type) => {
  const URL = process.env.REACT_APP_BACK_URI;

  let client, request;
  switch (type) {
    case "totals":
      request = new EventsRequest();
      client = grpc.client(EventService.GetTotals, {
        host: URL,
      });
      break;

    default:
      request = new EventsRequest();
      client = grpc.client(EventService.GetEvents, {
        host: URL,
      });
      break;
  }

  const start = (token) => {
    client.start(new grpc.Metadata({ authorization: `Bearer ${token}` }));
  };

  return { request, client, start };
};
