import { useState, useEffect } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./calendarStyle.css";
import { DateRangePicker, DefinedRange } from "react-date-range";
import { Box, FormControl, Select, Typography } from "@mui/material";
import Utils from "../Utils";
import * as constants from "../../../constants";
import useMediaQuery from "@mui/material/useMediaQuery";

const DateRange = ({ range, setRange, setIsSame }) => {
  const mobileView = useMediaQuery("(max-width:743px) and (min-width:300px)");
  const tabletView = useMediaQuery("(max-width:1280px) and (min-width:744px)");
  const boxPercentStyle = mobileView | tabletView ? "0" : "2rem 34.5% 0 0";
  const boxWidth = mobileView | tabletView ? "100%" : "31%";
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const boxStyle = {
    width: boxWidth,
    height: "3.5rem",
    margin: boxPercentStyle,
  };
  const textStyle = {
    marginLeft: "0.8rem",
  };

  const formStyle = {
    width: "100%",
    height: "75%",
    backgroundColor: "white",
  };
  const selectStyle = {
    height: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "solid 2px #2E117F",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "solid 2px #C31CF3",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": { border: "solid 2px #2E117F" },
    },
  };

  const utils = Utils();
  const [isOpen, setIsOpen] = useState(false);
  const [scrollOverride, setScrollOverride] = useState(false);
  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    selection.startDate.getTime() === selection.endDate.getTime()
      ? setIsSame(true)
      : setIsSame(false);
    setRange([selection]);
  };

  const handleClose = () => {
    if (scrollOverride) {
      setScrollOverride(false);
    }
    setIsOpen(false);
  };
  let element = document.getElementById("drp");

  const handleOpen = () => {
    if (
      element.getBoundingClientRect().bottom < 825 &&
      element.getBoundingClientRect().bottom > 600 &&
      !scrollOverride &&
      !mobileView
    ) {
      window.scrollBy(0, 300);
      setScrollOverride(true);
    }
    setIsOpen(true);
  };
  const handleScroll = () => {
    if (!scrollOverride) {
      setIsOpen(false);
    }
    setScrollOverride(false);
  };
  return (
    <Box sx={boxStyle}>
      <Typography sx={textStyle} variant="body2">
        {constants.DB_DDL_TIME_RANGE}
      </Typography>
      <FormControl sx={formStyle}>
        <Select
          id="drp"
          sx={selectStyle}
          displayEmpty
          value={range}
          open={isOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          renderValue={() => {
            return utils.formatDateDisplay(range[0]);
          }}
        >
          {mobileView ? (
            <DefinedRange
              onChange={handleOnChange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={range}
              direction="horizontal"
              className="dateRangePicker"
            />
          ) : (
            <DateRangePicker
              onChange={handleOnChange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={range}
              direction="horizontal"
              className="dateRangePicker"
            />
          )}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DateRange;
