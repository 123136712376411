import { memo } from "react";
import { Box, Typography } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import LoadingWheel from "../LoadingWheel";

const LineGraph = ({ data, type, loading, mobileView }) => {
  const legendPlacement = {
    ...(mobileView
      ? {
          anchor: "bottom-left",
          direction: "row",
          translateX: 0,
          translateY: 50,
          axisBottom: {
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "transportation",
            legendOffset: 36,
            legendPosition: "middle",
          },
          graphMargin: 50,
          symbolSize: 6,
          itemWidth: 140,
          fontSize: "10px",
        }
      : {
          anchor: "bottom-right",
          direction: "column",
          translateX: 150,
          translateY: 0,
          graphMargin: 150,
          symbolSize: 12,
          itemWidth: 140,
          fontSize: "12px",
          axisBottom: {
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Dates",
            legendOffset: 36,
            legendPosition: "middle",
          },
        }),
  };

  const divStyle = {
    height: "25rem",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
  const mDivStyle = { ...divStyle, overflowX: "scroll", overflowY: "hidden" };

  let dbTotals = [];
  let enTotals = [];
  let graphData = [];

  data.events !== undefined && data.events.length > 0
    ? data.events.map((x) =>
        x.eventType === "driving_by" ? dbTotals.push(x) : enTotals.push(x)
      )
    : (data = null);
  let nivoDbData = dbTotals.map((db) => {
    return { x: db.date, y: db.total };
  });

  let nivoEnData = enTotals.map((en) => {
    return { x: en.date, y: en.total };
  });
  nivoDbData.pop();
  nivoEnData.pop();
  type === "db"
    ? (graphData = [
        {
          id: "Users Driving By",
          color: "hsl(214,95%,56%)",
          data: nivoDbData,
        },
      ])
    : type === "en"
    ? (graphData = [
        {
          id: "Users Entering Location",
          color: "hsl(287,90%,53%)",
          data: nivoEnData,
        },
      ])
    : (graphData = [
        {
          id: "Users Driving By",
          color: "hsl(214,95%,56%)",
          data: nivoDbData,
        },
        {
          id: "Users Entering Location",
          color: "hsl(287,90%,53%)",
          data: nivoEnData,
        },
      ]);

  return (
    <Box sx={mobileView ? mDivStyle : divStyle}>
      {loading ? (
        <LoadingWheel width={4} />
      ) : data?.events ? (
        mobileView ? (
          <ResponsiveLine
            data={graphData}
            areaBaselineValue={0}
            margin={{
              top: 50,
              right: legendPlacement.graphMargin,
              bottom: 50,
              left: 70,
            }}
            width={1250}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            enableSlices="x"
            sliceTooltip={({ slice }) => {
              return (
                <div
                  style={{
                    background: "rgba(255, 255, 255, 0.75)",
                    padding: "4.5px 6px",
                    border: "1px solid #2E117F47",
                  }}
                >
                  <div>
                    <Typography variant="h4" color="primary">
                      {"Date: " + slice.points[0].data.x}
                    </Typography>
                  </div>
                  {slice.points.map((point) => (
                    <div
                      key={point}
                      style={{
                        display: "flex",
                        color: point.serieColor,
                        padding: "2px 0",
                      }}
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          background: point.serieColor,
                          marginRight: "3px",
                        }}
                      />
                      <Typography variant="h4">
                        {point.serieId}
                        {": "} {point.data.yFormatted}
                      </Typography>
                    </div>
                  ))}
                </div>
              );
            }}
            yFormat=" >-.2~f"
            curve="cardinal"
            axisTop={null}
            axisRight={null}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Number of Events",
              legendOffset: -60,
              legendPosition: "middle",
            }}
            axisBottom={null}
            colors={{ datum: "color" }}
            enablePoints={false}
            useMesh={true}
            crosshairType="cross"
            theme={{
              tooltip: {
                container: {
                  fontFamily: "montserrat",
                  fontSize: "13px",
                  color: "#2e107e",
                },
              },
            }}
            legends={[
              {
                anchor: legendPlacement.anchor,
                direction: legendPlacement.direction,
                justify: false,
                translateX: legendPlacement.translateX,
                translateY: legendPlacement.translateY,
                itemsSpacing: 2,
                itemDirection: "left-to-right",
                itemWidth: legendPlacement.itemWidth,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: legendPlacement.symbolSize,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        ) : (
          <ResponsiveLine
            data={graphData}
            areaBaselineValue={0}
            margin={{
              top: 50,
              right: legendPlacement.graphMargin,
              bottom: 50,
              left: 70,
            }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            enableSlices="x"
            sliceTooltip={({ slice }) => {
              return (
                <div
                  style={{
                    background: "rgba(255, 255, 255, 0.75)",
                    padding: "4.5px 6px",
                    border: "1px solid #2E117F47",
                  }}
                >
                  <div>
                    <Typography variant="h4" color="primary">
                      {"Date: " + slice.points[0].data.x}
                    </Typography>
                  </div>
                  {slice.points.map((point) => (
                    <div
                      key={point}
                      style={{
                        display: "flex",
                        color: point.serieColor,
                        padding: "2px 0",
                      }}
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          background: point.serieColor,
                          marginRight: "3px",
                        }}
                      />
                      <Typography variant="h4">
                        {point.serieId}
                        {": "} {point.data.yFormatted}
                      </Typography>
                    </div>
                  ))}
                </div>
              );
            }}
            yFormat=" >-.2~f"
            curve="cardinal"
            axisTop={null}
            axisRight={null}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Number of Events",
              legendOffset: -60,
              legendPosition: "middle",
            }}
            axisBottom={
              nivoDbData.length > 7 ? null : legendPlacement.axisBottom
            }
            colors={{ datum: "color" }}
            enablePoints={false}
            useMesh={true}
            crosshairType="cross"
            theme={{
              tooltip: {
                container: {
                  fontFamily: "montserrat",
                  fontSize: "13px",
                  color: "#2e107e",
                },
              },
            }}
            legends={[
              {
                anchor: legendPlacement.anchor,
                direction: legendPlacement.direction,
                justify: false,
                translateX: legendPlacement.translateX,
                translateY: legendPlacement.translateY,
                itemsSpacing: 2,
                itemDirection: "left-to-right",
                itemWidth: legendPlacement.itemWidth,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: legendPlacement.symbolSize,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        )
      ) : (
        <Typography variant="h4" color="primary">
          No data available
        </Typography>
      )}
    </Box>
  );
};

export default memo(LineGraph);
