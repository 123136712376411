import { Box, Button, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CSVLink } from "react-csv";
import "./DLChoiceModal.module.css";

const DLChoiceModal = ({ open, noHandler, yesHandler, data }) => {
  const modalBoxStyle = {
    position: "relative",
    top: "50%",
    left: "50%",
    display: "flex",
    flexDirection: "column",
    transform: "translate(-50%, -50%)",
    width: "20%",
    height: "15%",
    bgcolor: "background.paper",
    borderRadius: "5px",
  };
  const closeButtonStyle = { 
    position: "absolute",
    right: 0,
    p: "4%",
  };
  const iconStyle = { fontSize: "1rem" };
  const textStyle = {
    m: "auto 15%",
    textAlign: "center",
    fontWeight: 900,
  };
  const buttonBoxStyle = {
    display: "flex",
    justifyContent: "space-around",
  };
  const buttonStyle = {
    width: "50%",
    borderTop: "solid 1px",
    borderColor: "#2e117f",
    borderRadius: "0px",
  };
  const dlButtonStyle = {
    borderLeft: "solid 1px"
  };

  return (
    <Modal open={open}>
      <Box sx={modalBoxStyle}>
        <Button sx={closeButtonStyle} onClick={noHandler}>
          <CloseIcon sx={iconStyle} />
        </Button>
        <Typography sx={textStyle} variant="body1" color="primary">
          Download Data?
        </Typography>
        <Box sx={buttonBoxStyle}>
          <Button sx={buttonStyle} onClick={noHandler}>Cancel</Button>
          {data && (
            <Button sx={{...buttonStyle, ...dlButtonStyle}}>
              <CSVLink
                headers={data.headers}
                data={data.totals}
                filename={data.filename}
                onClick={yesHandler}
              >
                Download
              </CSVLink>
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default DLChoiceModal;
