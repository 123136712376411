import { memo } from "react";
import { Box, Typography } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import LoadingWheel from "../LoadingWheel";
import Data from "../Data";
const BarGraph = ({
  graphTitle,
  data,
  kind,
  loading,
  eventType,
  mobileView,
  isPercent,
}) => {
  const dataFetcher = Data();

  let graphData = [];
  let dataKeys = [];

  let indexedBy = "";
  const colorScheme = {
    //Ages
    "14-20": "hsl(258,30%,64%)",
    "21-30": "hsl(258,30%,64%)",
    "31-40": "hsl(258,30%,64%)",
    "41-50": "hsl(258,30%,64%)",
    "51-64": "hsl(258,30%,64%)",
    "65+": "hsl(258,30%,64%)",
    //Genders
    "Male (including transgender male)": "hsl(215,95%,55%)",
    "Female (including transgender female)": "hsl(287,89%,53%)",
    "Non-Binary": "hsl(140, 100%, 28%)",
    "Prefer to self describe": "hsl(267,81%,63%)",
    "Prefer not to specify": "hsl(256,76%,28%)",
  };
  const getColors = (bar) => colorScheme[bar.id];
  const customTooltipLabel = (bar) => {
    if (kind === "ageGender") {
      return bar.id + " (" + bar.indexValue + ")";
    } else {
      return bar.id;
    }
  };

  const legendStyle = {
    dataFrom: "keys",
    anchor: "bottom-right",
    direction: "column",
    justify: false,
    translateX: 150,
    translateY: 0,
    itemsSpacing: 2,
    itemWidth: 140,
    itemHeight: 20,
    itemDirection: "left-to-right",
    itemOpacity: 0.85,
    symbolSize: 12,
    effects: [
      {
        on: "hover",
        style: {
          itemOpacity: 1,
        },
      },
    ],
  };
  const wrapper = {
    height: "25rem",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
  };
  const mobileWrapper = {
    ...wrapper,
    overflowX: "scroll",
    overflowY: "hidden",
    paddingTop: "25px",
  };
  const labelStyle = { fontWeight: "800" };
  switch (kind) {
    case "age": {
      let ages = dataFetcher.getAges(data.ageList, eventType, isPercent);
      if (ages) {
        dataKeys = ages.labels;
        indexedBy = "ageGroup";
        if (ages.gdata) {
          for (let i = 0; i < dataKeys.length; i++) {
            const label = dataKeys[i];
            const temp = { ageGroup: ages.labels[i], [label]: ages.gdata[i] };
            graphData = [...graphData, temp];
          }
        }
      }
      break;
    }
    case "gender": {
      let genders = dataFetcher.getGenders(data.genList, eventType, isPercent);
      if (genders) {
        dataKeys = genders.labels;
        indexedBy = "genGroup";
        if (genders.gdata) {
          for (let i = 0; i < dataKeys.length; i++) {
            const label = dataKeys[i];
            const temp = {
              genGroup: genders.labels[i],
              [label]: genders.gdata[i],
            };
            graphData = [...graphData, temp];
          }
        }
      }
      break;
    }
    default: {
      let menStats = dataFetcher.getAgeGenders(
        data.ageGenStats,
        "Male (including transgender male)",
        eventType,
        isPercent
      );
      let womenStats = dataFetcher.getAgeGenders(
        data.ageGenStats,
        "Female (including transgender female)",
        eventType,
        isPercent
      );
      let nonBinaryStats = dataFetcher.getAgeGenders(
        data.ageGenStats,
        "Non-Binary",
        eventType,
        isPercent
      );
      let selfdescStats = dataFetcher.getAgeGenders(
        data.ageGenStats,
        "Prefer to self describe",
        eventType,
        isPercent
      );
      let unspecStats = dataFetcher.getAgeGenders(
        data.ageGenStats,
        "Prefer not to specify",
        eventType,
        isPercent
      );
      if ((menStats, womenStats, nonBinaryStats, selfdescStats, unspecStats)) {
        dataKeys = [
          "Male (including transgender male)",
          "Female (including transgender female)",
          "Non-Binary",
          "Prefer to self describe",
          "Prefer not to specify",
        ];
        indexedBy = "ages";
        if (
          (menStats.gdata,
          womenStats.gdata,
          nonBinaryStats.gdata,
          selfdescStats.gdata,
          unspecStats.gdata)
        ) {
          for (let i = 0; i < menStats.labels.length; i++) {
            const temp = {
              ages: menStats.labels[i],
              "Male (including transgender male)": menStats.gdata[i],
              "Female (including transgender female)": womenStats.gdata[i],
              "Non-Binary": nonBinaryStats.gdata[i],
              "Prefer to self describe": selfdescStats.gdata[i],
              "Prefer not to specify": unspecStats.gdata[i],
            };
            graphData = [...graphData, temp];
          }
        }
      }
      break;
    }
  }

  const BreakTickLabel = (tick) => {
    const { x, y, value, tickIndex } = tick;
    const tickSize = tickIndex % 2 === 0 ? 20 : 5;
    const dataValue = graphData[tickIndex];

    return (
      <g transform={`translate(${x},${y + 5})`}>
        <line x1={0} x2={0} y1={0} y2={tickSize} stroke="#777" />
        <text
          x={0}
          y={tickSize + 5}
          textAnchor="middle"
          dominantBaseline="hanging"
          style={{ fontFamily: "sans-serif", fontSize: "11px", fill: "#333" }}
        >
          {value}
        </text>
        {kind !== "ageGender" && (
          <text
            x={0}
            y={tickSize + 17}
            textAnchor="middle"
            dominantBaseline="hanging"
            style={{ fontFamily: "sans-serif", fontSize: "11px", fill: "#333" }}
          >
            {`${dataValue[value]}${isPercent ? "%" : ""}`}
          </text>
        )}
      </g>
    );
  };

  return (
    <Box sx={mobileView ? mobileWrapper : wrapper}>
      {loading ? (
        <LoadingWheel width={4} />
      ) : (
        <>
          <Typography align="center" variant="subtitle1">
            {graphTitle}
          </Typography>

          {mobileView ? (
            <ResponsiveBar
              data={graphData}
              keys={dataKeys}
              indexBy={indexedBy}
              margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
              maxValue={isPercent ? 100 : "auto"}
              width={700}
              padding={0.3}
              innerPadding={3}
              groupMode={kind === "ageGender" ? "grouped" : "stacked"}
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              colors={getColors}
              borderColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              axisTop={null}
              axisRight={null}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: isPercent ? "% of Total Users" : "# of Total Users",
                legendPosition: "middle",
                legendOffset: -60,
              }}
              axisBottom={{
                renderTick: (tick, index) => (
                  <BreakTickLabel key={index} {...tick} />
                ),
              }}
              enableGridX={true}
              enableLabel={false}
              theme={{
                tooltip: {
                  container: {
                    fontFamily: "montserrat",
                    fontSize: "13px",
                    color: "#2e107e",
                  },
                },
              }}
              tooltipLabel={customTooltipLabel}
              tooltip={({ id, value, indexValue, color }) => (
                <div
                  style={{
                    display: "flex",
                    background: "rgba(255, 255, 255)",
                    padding: "4.5px 6px",
                    border: "1px solid #2E117F47",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      background: color,
                      marginRight: "3px",
                    }}
                  />
                  <Typography variant="h3" color="primary" sx={labelStyle}>
                    {id} {kind === "ageGender" && " (" + indexValue + ")"} :{" "}
                    {value}
                    {isPercent && "%"}
                  </Typography>
                </div>
              )}
              legends={[]}
              role="application"
            />
          ) : (
            <ResponsiveBar
              data={graphData}
              keys={dataKeys}
              indexBy={indexedBy}
              margin={{
                top: 20,
                right: 220,
                bottom: 80,
                left: 70,
              }}
              maxValue={"auto"}
              padding={0.3}
              innerPadding={3}
              groupMode={kind === "ageGender" ? "grouped" : "stacked"}
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              colors={getColors}
              borderColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              axisTop={null}
              axisRight={null}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: isPercent ? "% of Total Users" : "# of Total Users",
                legendPosition: "middle",
                legendOffset: -60,
              }}
              axisBottom={{
                renderTick: BreakTickLabel,
              }}
              enableGridX={true}
              enableLabel={false}
              theme={{
                tooltip: {
                  container: {
                    fontFamily: "montserrat",
                    fontSize: "13px",
                    color: "#2e107e",
                  },
                },
              }}
              tooltipLabel={customTooltipLabel}
              tooltip={({ id, value, indexValue, color }) => (
                <div
                  style={{
                    display: "flex",
                    background: "rgba(255, 255, 255)",
                    padding: "4.5px 6px",
                    border: "1px solid #2E117F47",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      background: color,
                      marginRight: "3px",
                    }}
                  />
                  <Typography variant="h4" color="primary" sx={labelStyle}>
                    {id} {kind === "ageGender" && " (" + indexValue + ")"} :{" "}
                    {value}
                    {isPercent && "%"}
                  </Typography>
                </div>
              )}
              legends={[legendStyle]}
              role="application"
            />
          )}
        </>
      )}
    </Box>
  );
};
export default memo(BarGraph);
