import { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";

const CountryButton = (props) => {
  const [iconOpacity, setIconOpacity] = useState("100%");
  const [visibilityState, setVisibilityState] = useState("hidden");

  const buttonStyle = {
    height: "2rem",
    width: "2rem",
    margin: "0.5rem 0.7rem 0 0",
  };
  const iconStyle = {
    position: "relative",
    width: "2rem",
    height: "2rem",
    borderRadius: props.radius,
    opacity: iconOpacity,
  };
  const checkmarkStyle = {
    position: "absolute",
    width: "1rem",
    height: "1rem",
    visibility: visibilityState,
  };

  useEffect(() => {
    if (props.active === props.name) {
      setIconOpacity("60%");
      setVisibilityState("visible");
    } else {
      setIconOpacity("100%");
      setVisibilityState("hidden");
    }
  }, [props.active, props.name]);

  return (
    <Button sx={buttonStyle} onClick={props.clickFunction}>
      <Box component="img" sx={iconStyle} src={props.src} alt={props.name} />
      <Box
        component="img"
        sx={checkmarkStyle}
        src={"images/check-mark.png"}
        alt={"Selected"}
      />
    </Button>
  );
};

export default CountryButton;
