import { Box, Typography } from "@mui/material";
import LoadingWheel from "../LoadingWheel";

const StatDisplay = ({
  width,
  height,
  textWidth,
  textType,
  tbPadding,
  rightPadding,
  statName,
  statNumber,
  loading,
}) => {
  const containerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: width,
    height: height,
    padding: tbPadding + " 0",
    paddingRight: rightPadding,
  };
  const textBoxStyle = {
    display: "flex",
    flexFlow: "column wrap",
    justifyContent: "center",
    width: textWidth,
  };

  return (
    <Box sx={containerStyle}>
      <Box sx={textBoxStyle}>
        <Typography variant={textType} color="primary">
          {statName}
        </Typography>
      </Box>
      {loading ? (
        <Box>
          <LoadingWheel width={3} />
        </Box>
      ) : (
        <Typography variant="h1" color="#207DF9">
          {statNumber}
        </Typography>
      )}
    </Box>
  );
};

export default StatDisplay;
