import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Firebase from "./components/Firebase";
import reportWebVitals from "./reportWebVitals";
import { GlobalProvider } from "./components/common/Context";

const fsvc = Firebase();
fsvc.getFirebase().then(() => {
  ReactDOM.render(
    <GlobalProvider fsvc={fsvc}>
      <App />
    </GlobalProvider>,
    document.getElementById("root")
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
