import { useEffect } from "react";
import { Grpc } from "./grpc";

const procHeaders = (headers, data, cb) => {
  const status = headers.headersMap["grpc-status"];
  if (!status) {
    return;
  }

  switch (status[0]) {
    case "0":
      break;
    case "16":
      //grpc unauthorized code
      if (cb) {
        cb({ ...data });
      }
      break;
    default: {
      const message = headers.headersMap["grpc-message"][0];
      cb(data ? { ...data, ...{ error: message } } : { error: message });
    }
  }
};

export const useGetTotals = (
  authUser,
  company,
  selectedDates,
  selPyn,
  procData,
  selState,
  selCity
) => {
  useEffect(() => {
    const fetchData = async () => {
      if (authUser && company?.id) {
        const token = await authUser.getIdToken();

        const api = Grpc("totals");

        const client = api.client;
        const request = api.request;

        if (selPyn.id !== "" && selPyn.id !== "all") {
          request.setPyn(selPyn.id);
        }
        if (selCity.name !== "") {
          request.setCity(selCity.name);
        }

        if (selState.state !== "") {
          request.setState(selState.state);
        }

        if (selState.code !== "") {
          request.setState(selState.code);
        }

        switch (selectedDates.type) {
          case "date":
            request.setFrom(selectedDates.start);
            request.setTo(selectedDates.end);
            request.setType("date");
            break;
          case "range":
            request.setFrom(selectedDates.start);
            request.setTo(selectedDates.end);
            request.setType("range");
            break;
          default:
            request.setType("range");
            request.setFrom(selectedDates.interval);
        }
        request.setCompany(company?.id);

        client.onHeaders((headers) => {
          procHeaders(headers, null, procData);
        });
        client.onMessage((message) => {
          procData(message.toObject());
        });

        client.onEnd(() => {});

        api.start(token);
        client.send(request);
      }
    };

    try {
      fetchData();
    } catch (err) {
      procData({ error: err });
    }
  }, [authUser, company, selectedDates, selPyn, selState, selCity]);
};

export const useReporting = (
  authUser,
  company,
  selectedDates,
  selPyn,
  loadingCb,
  procData,
  selState,
  selCity,
  selectedEventType
) => {
  useEffect(() => {
    loadingCb(true);
    const fetchData = async () => {
      if (authUser && company?.id) {
        const token = await authUser.getIdToken();

        const api = Grpc("events");

        const client = api.client;
        const request = api.request;

        if (selPyn.id !== "" && selPyn.id !== "all") {
          request.setPyn(selPyn.id);
        }
        if (selCity.name !== "") {
          request.setCity(selCity.name);
        }

        if (selState.state !== "") {
          request.setState(selState.state);
        }

        if (selState.code !== "") {
          request.setState(selState.code);
        }

        request.setEventType(selectedEventType);

        switch (selectedDates.type) {
          case "date":
            request.setFrom(selectedDates.start);
            request.setTo(selectedDates.end);
            request.setType("date");
            break;
          case "range":
            request.setFrom(selectedDates.start);
            request.setTo(selectedDates.end);
            request.setType("range");
            break;
          default:
            request.setType("range");
            request.setFrom(selectedDates.interval);
        }
        request.setCompany(company?.id);

        client.onHeaders((headers) => {
          procHeaders(headers, null, procData);
        });
        client.onMessage((message) => {
          procData(message.toObject());
        });

        client.onEnd(() => {
          loadingCb(false);
        });

        api.start(token);
        client.send(request);
      }
    };

    try {
      fetchData();
    } catch (err) {
      procData({ error: err });
      loadingCb(false);
    }
  }, [
    authUser,
    company,
    selectedDates.start,
    selectedDates.end,
    selPyn,
    selState,
    selCity,
    selectedEventType,
  ]);
};
