// General
export const COMPANY_NAME = "TRYPSCORE FOR BUSINESS";
export const NOT_FOUND = "Not Found";
export const RETURN_HOME = "Return Home";

// NavBar
export const NB_DB = "Dashboard";
export const NB_CI = "Company Info";
export const NB_CS = "Company Selection";
export const NB_CI_M = "Info";
export const NB_CS_M = "Selection";
export const NB_LOGOUT = "LOG OUT";

// Dashboard
export const PAGE_TITLE = "WELCOME TO TRYPSCORE FOR BUSINESS";
export const DB_LINK = "/Home";
export const DB_DESC =
  "Please use the filtering options below to customize your results. Once " +
  "you have applied your desired filters, your results will appear below in " +
  "the information blocks.";
export const DB_DESC_M =
  "Please use the filtering options below to customize your results.";
export const DB_SWITCH_PERCENT = "%";
export const DB_SWITCH_NUM = "#";
export const DB_FILTER_OPTIONS = "FILTER OPTIONS";
export const DB_FILTER_OPTIONS_DESC =
  "Use the filters below to customize your results";
export const DB_DDL_PROVINCES = "Provinces";
export const DB_DDL_STATES = "States";
export const DB_DDL_CITIES = "Cities (optional)";
export const DB_DDL_LOCATIONS = "Locations (optional)";
export const DB_DDL_EVENT_TYPE = "Event Type";
export const DB_DDL_TIME_RANGE = "Time Range";
export const DB_DDL_STATES_PROMPT = "Pick provinces here";
export const DB_DDL_CITY_PROMPT = "Pick cities here";
export const DB_DDL_LOCATION_PROMPT = "Pick locations here";
export const DB_DDL_EVENT_TYPE_PROMPT = "Pick the Event Type Here";
export const DB_DDL_TIME_RANGE_PROMPT = "Pick a Time Range Here";
export const DB_DDL_PROMPT = "Select here";

export const DB_DDL_EVENT_TYPE_DB = "Users Driving By";
export const DB_DDL_EVENT_TYPE_EN = "Users Entering Location";
export const DB_DDL_EVENT_TYPE_ALL =
  "Users Driving By / Users Entering Location";

export const DB_VIEW_RESULTS_BELOW = "VIEW RESULTS BELOW";
export const DB_USER_STATS_NATIONAL = "NATIONAL";
export const DB_USER_STATS_STATE = "BY STATE";
export const DB_USER_STATS_PROVINCIAL = "PROVINCIAL";
export const DB_USER_STATS_DESC =
  "How do users interact with your location(s)?";
export const DB_USER_STATS_DRIVING_BY = "Driving By:";
export const DB_USER_STATS_ENTERING_LOCATION = "Entering Location:";
export const DB_USER_STATS_AVG_VISIT = "Average Visit (Min):";
export const DB_USER_STATS_GRAPH_TITLE_EN = "USERS ENTERING LOCATION";
export const DB_USER_STATS_GRAPH_TITLE_DB = "USERS DRIVING BY";
export const DB_USER_STATS_GRAPH_TITLE_ALL =
  "USERS DRIVING BY / USERS ENTERING LOCATION";
export const DB_USER_STOPS =
  "How many of the users that drove by your location(s) stopped to visit?";
export const DB_NO_LINE_DATA =
  "Not enough data can be loaded for the current date selection. Please increase your date range selection to two days or more.";
export const DB_PYN_TITLE = "PYN DETAILS";
export const DB_PYN_DESC = "How many Pyns are collected at your location(s)?";
export const DB_PYN_TIME_RANGE = "Results affected by time range";
export const DB_PYN_TIME_RANGE_M = "affected by time range";
export const DB_PYN_NO_TIME_RANGE =
  "Lifetime results not affected by time range";

export const DB_PYN_COLLECTED_TIME_RANGE = "Pyns Collected in Time Range:";
export const DB_PYN_COLLECTED_TIME_RANGE_T = "Pyns Collected:";
export const DB_PYN_ACTIVATED_NOT_COLLECTED =
  "Pyns Activated but Not Collected:";
export const DB_PYN_ACTIVATED_NOT_COLLECTED_T = "Pyns Expired:";
export const DB_PYN_AVG_WEEK = "Average Number of Pyns Collected per Week:";
export const DB_PYN_AVG_WEEK_T = "Avg Pyns per Week:";
export const DB_PYN_AVG_MONTH = "Average Number of Pyns Collected per Month:";
export const DB_PYN_AVG_MONTH_T = "Avg Pyns per Month:";
export const DB_PYN_COLLECTED_LOCATIONS = "Pyns Collected at Location(s):";
export const DB_PYN_COLLECTED_LOCATIONS_T = "Pyns Collected:";

export const DB_DEM_TITLE = "DEMOGRAPHICS";
export const DB_DEM_DESC = "Pyns Collected Within Specific Time Ranges";
export const DB_BG_TITLE_1 = "Age and Gender";
export const DB_BG_TITLE_2 = "Age";
export const DB_BG_TITLE_3 = "Gender";

export const DB_BG_MOBILE_TOGGLE = "View options";

export const DB_BG_YAXIS_NUM = "# of Total Users";
export const DB_BG_YAXIS_PER = "% of Total Users";

export const DB_TOTAL = "Total: ";
export const DB_DOWNLOAD = "Download All Data";
export const DB_DOWNLOAD_SUCCESS = "Data successfully downloaded!";
export const DB_DOWNLOAD_ALL_SUCCESS = "All data successfully downloaded!";

// Company Selection
export const CS_PAGE_TITLE = "COMPANY SELECTION";
export const CS_LINK = "/CompanySelection";
export const CS_DESC =
  "Choose a country and a company to view more information. Once you choose " +
  "your desired company, the information results will update to the chosen " +
  "company.";

export const CS_DESC_M =
  "Choose a country and a company to view more information.";
export const CS_CHOOSE_COUNTRY = "Choose Country";
export const CS_CHOOSE_PROMPT = "Pick or search company here";

// Signin
export const SIGNIN_LINK = "/";
export const SIGNIN_BAD_EMAIL = "Entered value does not match email format";
export const SIGNIN_SHORT_PW = "min length is 5";
export const SIGNIN_BTN = "Sign in";
export const SIGNIN_FORGOT_PASSWORD = "Forgot Password";
export const SIGNIN_GOOGLE_BTN = "Sign in with google";
export const SIGNIN_NO_ACCESS = "Sorry, you do not have access to this service";
export const SIGNIN_BAD_CREDS = "Bad email or password";
export const LINK_LIST = [
  "Creating your account",
  "Linking your tryps",
  "Gathering your scores",
  "Finishing up",
];

//Form
export const FRM_USER_SUCCESS = "Successfully saved user information";
export const FRM_FNAME_REQUIRED = "First Name is Required";
export const FRM_LNAME_REQUIRED = "Last Name is Required";
export const FRM_EMAIL_REQUIRED = "Email is Required";
export const FRM_EMAIL_INVALID = "invalid email address";
export const FRM_PHONE_INVALID = "phone needs to follow ###-###-####";
export const FRM_ADD_SUCCESS =
  "User added Successfully, The invitee will receive an email link.";
export const FRM_ADD_FAIL = "Error adding user, please try again";
export const FRM_EDIT_FAIL = "Error updating user, please try again";

//Sign Up
export const SIGN_UP_CREATE = "Create Account";
export const SIGN_UP_SUCCESS =
  "User has been created successfully, please look in your email inbox for a verification email.";
export const SIGN_UP_INVALID_LINK = "This link is invalid";
export const SIGN_UP_CONFIRM_PASS = "Please Confirm Password";
export const SIGN_UP_PROVIDE_PASS = "Please Provide a Password";
export const SIGN_UP_PASS_NOT_MATCH = "Passwords must match";
export const SIGN_UP_ROUTE = "/finishsignup";
export const SIGN_UP_TEXT =
  "You have been invited to join Trypscore for Business. To access the tool, you must finish creating your account.";
export const SIGN_UP_MODAL_TITLE = "VERIFY YOUR EMAIL";
export const SIGN_UP_MODAL_TEXT =
  "Welcome to Trypscore for Business! An email verification link has been sent to the email address you provided. Please click the link in the email to proceed.";
export const SIGN_UP_MODAL_BTN = "Okay";
export const CANCEL = "Cancel";
export const SIGN_UP_INVALID_INV =
  "Your Invite is Invalid. Request a new invite";

//Verify
export const VERIFY_CONFIRMATION_TITLE = "Email Succesfully Verified";
export const VERIFY_CONFIRMATION_LOGIN =
  "Please sign in to access your account";
export const VERIFY_ERROR_TITLE = "Email Not Verified";
export const VERIFY_RESEND_VERIFICATION =
  "Resend verification email and return to sign in";

//Set Password
export const SET_PASS_PATH = "/setpass";
export const RESET_PASS_CTITLE = "Password Reset";
export const RESET_PASS_ERROR =
  "Error resetting password, would you like to resend the password reset email?";
export const SET_PASS_TITLE = "Create a New Password";
export const SET_PASS_TEXT =
  "Your new password must be different from previously used passwords";
export const SET_PASS_BTN = "Reset Password";
export const SET_DLG_TITLE = "Your password must contain";
export const SET_DLG_REQ1 = "Minimum of 8 characters";
export const SET_DLG_REQ2 =
  "Must contain at least one uppercase and one lowercase letter";
export const SET_DLG_REQ3 = "Must include one number";
export const SET_DLG_REQ4 =
  "Must include one special character (e.g., ! @ # ?)";
export const SET_PASS_FORMAT_MSG =
  "Password must follow provided format, click help icon for description";
export const SET_PASS_CONFM = "Your password has been changed";
// Pass Reset
export const RESET_PATH = "/resetpass";
export const RESET_TITLE = "Forgot Password";
export const RESET_TEXT =
  "Please enter the email associated with your account and we'll send an email with a link to reset your password";
export const RESET_SEND_BTN = "Send Recovery Email";
export const RESET_BACK_BTN = "⬅ Go back to sign in page";
export const RESET_CONFIRM = "A recovery email has been sent";
export const RESET_TRY_AGAIN = "Please try again";

// Old Company Info still being used
export const CI_PW_RESET_OPTION = "Send Password Reset";
export const CI_PW_RESET_NO = "NO";
export const CI_PW_RESET_YES = "YES";
export const CI_PW_RESET_CONFIRM = "PASSWORD RESET SUCCESSFULLY SENT.";
export const CI_PW_RESET_OK = "OK";

// Legends
export const GENDER_MAN = "Male (including transgender male)";
export const GENDER_WOM = "Female (including transgender female)";
export const GENDER_NB = "Non-Binary";
export const GENDER_SELF = "Self-Described";
export const GENDER_NOT = "Prefer Not to Say";
export const LEGEND_TITLE = "Legend";

// Link Account
export const LN_SUB_TITLE = "Are you an existing client?";
export const LN_BODY_TEXT =
  "You may use your existing account credentials if you have signed up with an other TrypScore tools before. If not, please create an account";
export const LN_BTN_YES = "Yes, link my account";
export const LN_BTN_NO = "No, create account";
export const LINK_PATH = "/link";
export const LINK_HANG = "Hang Tight!";

export const ENV_URLS = {
  "trypscore-dev": "https://trypscore-admin-develop.web.app",
  trypscore: "https://trypscore-admin-staging.web.app",
  "trypscore-prod": "https://trypscore-admin.web.app",
};

export const DEV_PROJECT_IDS = ["trypscore-dev", "trypscore"];

export const PROJECTS = [
  {
    name: "Develop",
    id: "trypscore-dev",
    url: "https://trypscore-bi-develop-v2.web.app/",
    icons: ["😈"],
  },
  {
    name: "Staging",
    id: "trypscore",
    url: "https://trypscore-bi-staging.web.app/",
    icons: [],
  },
  {
    name: "North America",
    id: "trypscore-prod",
    url: "https://trypscore-bi-v2.web.app/",
    icons: ["🇺🇸", "🇨🇦"],
  },
  {
    name: "United Kingdom",
    id: "trypscore-prod-uk",
    url: "https://dashboard.saferoadschallenge.uk/", // TODO: PLACEHOLDER 05/31/24
    icons: ["🇬🇧"],
  },
];
