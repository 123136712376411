import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import DLConfirmModal from "../DLConfirmModal";
import DLChoiceModal from "../DLChoiceModal";
import * as constants from "../../../constants";
import useMediaQuery from "@mui/material/useMediaQuery";

const ReBox = ({ children, width, title, desc, data }) => {
  const mobileView = useMediaQuery("(max-width:743px) and (min-width:300px)");

  // MOBILE STYLES
  const mobileContainerStyle = {
    p: "5%",
  };

  // WEB APP STYLES
  const containerStyle = {
    boxShadow: "-1px 1px 5px 2px #e3e3e3",
    backgroundColor: "#fefefe",
    display: "flex",
    flexDirection: "column",
    borderRadius: "3px",
    p: "3%",
    m: "2rem 0",
    width: width,
  };
  const headerBoxStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  };
  const textBoxStyle = { width: "90%" };
  const buttonStyle = { height: "2rem" };
  const iconStyle = { fontSize: "1rem" };

  const [choiceOpen, setChoiceOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleDownload = () => {
    setChoiceOpen(false);
    setConfirmOpen(true);
  };

  return (
    <Box
      sx={{
        ...containerStyle,
        ...(mobileView && {
          ...mobileContainerStyle,
        }),
      }}
    >
      <Box sx={headerBoxStyle}>
        <Box sx={textBoxStyle}>
          <Typography color="primary" variant="h2">
            {title}
          </Typography>
          <Typography variant="subtitle1">{desc}</Typography>
        </Box>
        {data && (
          <Button sx={buttonStyle} onClick={() => setChoiceOpen(true)}>
            <DownloadIcon sx={iconStyle} />
          </Button>
        )}
      </Box>
      <DLChoiceModal
        open={choiceOpen}
        noHandler={() => setChoiceOpen(false)}
        yesHandler={handleDownload}
        data={data}
      />
      <DLConfirmModal
        text={constants.DB_DOWNLOAD_SUCCESS}
        open={confirmOpen}
        clickHandler={() => setConfirmOpen(false)}
      />
      {children}
    </Box>
  );
};

export default ReBox;
