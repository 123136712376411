const SteeringWheel = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 1080 1080"
      version="1.1"
      viewBox="0 0 1080 1080"
      xmlSpace="preserve"
    >
      <g transform="translate(-154.117 -537.312)">
        <path d="M691.4 819.5c-141.3 0-255.9 114.6-255.9 255.9s114.6 255.9 255.9 255.9 255.9-114.6 255.9-255.9-114.6-255.9-255.9-255.9zm-21.4 467c-93.6-9.5-169.7-79.5-187.1-171.9h40c76.9 0 147.1 70.4 147.1 147.3v24.6zm-46.4-263.9c-25.7 28.7-62.6 44.9-101.1 44.3h-43.1c4.5-113.8 98.1-203.7 212-203.7 28.8.1 57.3 5.9 83.8 17.2 75.3 32.1 125.2 104.8 128.2 186.5h-38.7c-38.5.6-75.4-15.6-101-44.3-37.1-38.7-98.5-40-137.2-2.8-1 1-2 1.9-2.9 2.8zm94 263.3v-23.7c0-77 70.4-147.7 147.4-147.7h34.9c-17 90.7-90.8 160-182.3 171.4z"></path>
        <path
          fillRule="evenodd"
          d="M453.9 963.2c-48.7-8.7-98.1 145.9-23.4 180.6 27.3 14.4 59.7 15.6 88.1 3.3 12.9-4.3 19.9-18.3 15.6-31.2-1.8-5.3-5.2-9.8-9.9-12.9 10.7-3.2 16.7-14.4 13.6-25-1.9-6.5-6.9-11.5-13.4-13.5 13.6-3.1 22.1-16.7 18.9-30.3-1.7-7.2-6.3-13.2-12.8-16.7 10.6-3.4 16.4-14.7 13.1-25.3-2.1-6.5-7.3-11.5-13.9-13.3l-75.9-15.7zm399.9 4.3c-26.6 14.7-29.2 33.9-13.3 57-42.3-12.9-36.4-54.6 13.3-57zm80.6-4.3c48.7-8.7 98.1 145.9 23.4 180.6-27.3 14.4-59.7 15.6-88.1 3.3-12.9-4.3-19.9-18.3-15.6-31.2 1.8-5.3 5.2-9.8 9.9-12.9-10.7-3.2-16.7-14.4-13.6-25 1.9-6.5 6.9-11.5 13.4-13.5-13.6-3.2-22.1-16.7-18.9-30.3 1.7-7.1 6.3-13.2 12.8-16.6-10.6-3.4-16.4-14.7-13.1-25.3 2.1-6.5 7.3-11.5 13.9-13.3l75.9-15.8zm-399.9 4.3c26.6 14.7 29.2 33.9 13.4 57 42.3-12.9 36.3-54.6-13.4-57z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default SteeringWheel;
