import { useContext } from "react";
import Dashboard from "../Dashboard";
import CompanySelection from "../CompanySelection";
import GlobalContext from "../common/Context";
import PageWrapper from "../common/PageWrapper";

const Home = () => {
  const { optionState } = useContext(GlobalContext);

  return (
    <>
      <PageWrapper display={optionState === "dashboard"}>
        <Dashboard />
      </PageWrapper>
      <PageWrapper display={optionState === "selection"}>
        <CompanySelection />
      </PageWrapper>
    </>
  );
};

export default Home;
