import { Typography } from "@mui/material";

const PageSubHeader = ({ children, pageTitle }) => {
  const style = { mt: "6rem", mb: "1.5rem" };

  return (
    <>
      <Typography sx={style} align="left" color="primary" variant="h1">
        {pageTitle}
      </Typography>
      {children}
    </>
  );
};

export default PageSubHeader;
