import { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import getCustomMuiTheme from "./CustomMuiTheme";
import Home from "./components/Home";
import SignIn from "./components/SignIn";
import Layout from "./components/common/Layout";
import { useFirebaseAuthentication } from "./components/Firebase";
import GlobalContext from "./components/common/Context";
import NotFound from "./components/common/NotFound";
import Navbar from "./components/common/Navbar";
import LoadingModal from "./components/common/LoadingModal";
import { useScript } from "./components/common/Utils";

import * as constants from "./constants";

const customTheme = getCustomMuiTheme();

function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: constants.SIGNIN_LINK,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component: Component, authenticated, signUp, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        !authenticated || signUp ? (
          <Component {...props} />
        ) : (
          <Redirect to={constants.DB_LINK} />
        )
      }
    />
  );
}

const App = () => {
  useScript(
    "https://static.zdassets.com/ekr/snippet.js?key=41c7d9c3-9cfe-456b-9306-34b36998964a",
    "ze-snippet"
  );

  const { firebaseState, signLoad, setCurrentUser } = useContext(GlobalContext);

  const { authUser, appFirstLoading } = useFirebaseAuthentication(
    firebaseState.firebase
  );
  const authenticated = authUser?.authUser?.uid;
  const admin = authUser?.permissions?.medidas;

  useEffect(() => {
    (!authUser.authUser || !signLoad) && setCurrentUser(authUser);
  }, [authUser, setCurrentUser, signLoad]);

  return appFirstLoading ? (
    <LoadingModal width={6} />
  ) : (
    <Router>
      <ThemeProvider theme={customTheme}>
        <Layout>
          <Navbar isLoggedIn={authenticated} isAdmin={admin} />
          <Switch>
            <PublicRoute
              exact
              path={constants.SIGNIN_LINK}
              authenticated={authenticated}
              component={SignIn}
            />
            <PrivateRoute
              path={constants.DB_LINK}
              authenticated={authenticated}
              component={Home}
            />
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </ThemeProvider>
    </Router>
  );
};

export default App;
