import { Suspense } from "react";
import PageErrorBoundary from "../PageErrorBoundary";
import ConditionalDisplay from "../ConditionalDisplay";
import LoadingModal from "../LoadingModal";

const PageWrapper = ({ render, display, unmount, children }) => {
  return (
    <ConditionalDisplay render={render} display={display} unmount={unmount}>
      <Suspense fallback={<LoadingModal width={6} />}>
        <PageErrorBoundary>{children}</PageErrorBoundary>
      </Suspense>
    </ConditionalDisplay>
  );
};

export default PageWrapper;
