import { useContext, useEffect, useRef, useState } from "react";
import CSVData from "../common/CSVData";
import FilterExpansion from "../common/FilterExpansion";
import PageSubHeader from "../common/PageSubHeader";
import DropdownCheckbox from "../common/DropdownCheckbox";
import { Box, Button, Container, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import DLConfirmModal from "../common/DLConfirmModal";
import DLChoiceModal from "../common/DLChoiceModal";
import ReBox from "../common/ReBox";
import LineGraph from "../common/LineGraph/LineGraph";
import BarGraph from "../common/BarGraph/BarGraph";
import StatDisplay from "../common/StatDisplay";
import SingleCheckSelect from "../common/SingleCheckSelect";
import GlobalContext from "../common/Context";
import { useReporting, useGetTotals } from "../API";
import * as constants from "../../constants";
import DateRange from "../common/DateRange";
import { addDays, subDays } from "date-fns";
import Utils from "../common/Utils";
import useMediaQuery from "@mui/material/useMediaQuery";
import LegendDialog from "../common/LegendDialog";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const defaultState = { code: "", name: "" };
const defaultCity = { name: "" };
const defaultPyn = { id: "", name: "" };

const Dashboard = () => {
  const { currentUser, companyState, firebaseState } =
    useContext(GlobalContext);
  const utils = Utils();
  const tabletView = useMediaQuery("(max-width:1280px) and (min-width:744px)");
  const mobileView = useMediaQuery("(max-width:743px) and (min-width:300px)");
  const titleMargin = tabletView ? "-1.5rem" : mobileView ? "-3rem" : "1rem";
  const pageWidth = mobileView ? "94%" : "90%";

  const containerStyle = { maxWidth: pageWidth };
  const titleBoxStyle = {
    width: "100%",
    mt: titleMargin,
    mb: "2rem",
  };
  const subtitleBoxStyle = {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  };
  const descriptionStyle = { width: "60%" };
  const filterBorderStyle = {
    border: "0.5px solid #2E117F",
    backgroundColor: "#2E117F",
  };
  const spacingBoxStyle = { mt: mobileView ? "2rem" : "4rem" };
  const reBoxContainerStyle = {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
  };
  const FOStylingMobile = {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: "100%",
  };
  const FOStylingNorm = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  };
  const bottomBoxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "2rem 0",
  };
  const buttonStyle = {
    height: "2rem",
    ml: "1rem",
  };
  const downloadIconStyle = {
    fontSize: "1rem",
  };

  const tbpadding = mobileView ? "1.5rem" : "3rem";
  const statWidth = "35%";
  const graphWidth = "62%";
  const tabletWidth = "100%";
  let dashOverride = true;
  const activeDataType = true;

  const [isPercent, setIsPercent] = useState(false);
  const [reportingLoading, setReportingLoading] = useState(false);

  const [legendOpen, setLegendOpen] = useState(false);
  const [choiceOpen, setChoiceOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [rows, setRows] = useState([]);

  const [selectedProvince, setSelectedProvince] = useState(defaultState);
  const [selectedCity, setSelectedCity] = useState(defaultCity);
  const [selectedLocation, setSelectedLocation] = useState(defaultPyn);

  const [selectedEventType, setSelectedEventType] = useState("all");
  const [selectedDataSet, setSelectedDataSet] = useState("all");

  const [sameDate, setSameDate] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const [formattedDates, setFormattedDates] = useState(
    utils.formatDate(dateRange[0])
  );

  const [states, setStates] = useState();
  const [cities, setCities] = useState();

  const iconStyle =
    selectedDataSet === "age"
      ? {
          fontSize: "1rem",
          ml: "72vw",
          mt: "-35px",
          opacity: "50%",
        }
      : {
          fontSize: "1rem",
          ml: "72vw",
          mt: "-35px",
        };

  const eventTogglers = [
    {
      dataType: "db",
      dataLabel: constants.DB_DDL_EVENT_TYPE_DB,
    },
    {
      dataType: "en",
      dataLabel: constants.DB_DDL_EVENT_TYPE_EN,
    },
    {
      dataType: "all",
      dataLabel: constants.DB_DDL_EVENT_TYPE_ALL,
    },
  ];

  const mobileBarGraphToggle = [
    {
      dataType: "all",
      dataLabel: constants.DB_BG_TITLE_1,
    },
    {
      dataType: "age",
      dataLabel: constants.DB_BG_TITLE_2,
    },
    {
      dataType: "gen",
      dataLabel: constants.DB_BG_TITLE_3,
    },
  ];

  const eventStats = useRef({
    events: [],
  });
  const defaultUserStats = {
    drivingBy: 0,
    enteringLocation: 0,
    avgVisitMin: 0,
  };
  const percentBtnStyle = {
    p: "0.5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "6rem",
    height: "3rem",
    mt: "1rem",
  };

  const [userStats, setUserStats] = useState(defaultUserStats);

  const ageStats = useRef({
    ageList: [],
  });
  const genStats = useRef({
    genList: [],
  });
  const ageGenStats = useRef({
    ageGenList: [],
  });

  const pynDetails = useRef({
    pynsCollectedTotal: 0,
    pynsActivatedTotal: 0,
    avgColPynsWeekly: 0,
    avgColPynsMonthly: 0,
    pynsCollInRange: 0,
    pynsActInRange: 0,
  });

  const CSVDataGetter = CSVData();
  const CSVTotals = CSVDataGetter.getCSVTotals(userStats, formattedDates);
  const CSVEvents = CSVDataGetter.getCSVEvents(eventStats, formattedDates);
  const CSVDemographics = CSVDataGetter.getCSVDemographics(
    ageStats,
    genStats,
    ageGenStats,
    formattedDates
  );
  const allCSVData = CSVDataGetter.getAllCSVData(
    userStats,
    eventStats,
    pynDetails,
    ageStats,
    genStats,
    ageGenStats,
    formattedDates
  );

  useEffect(() => {
    const getStates = async () => {
      if (!companyState?.id) return;

      setStates();
      const data = await firebaseState.getStatesByCompany(companyState.id);
      setStates(data);
    };
    getStates();
  }, [companyState, firebaseState]);

  useEffect(() => {
    const getCities = async () => {
      if (!companyState?.id || !selectedProvince?.code) return;

      setCities();
      const data = await firebaseState.getCitiesByCompanyState(
        companyState.id,
        selectedProvince.code
      );
      setCities(data);
    };
    getCities();
  }, [companyState, firebaseState, selectedProvince]);

  useEffect(() => {
    const getPyns = async () => {
      if (!companyState?.id || !selectedProvince.code || !selectedCity.name)
        return;

      setRows();
      const data = await firebaseState.getPynsByCompany(
        companyState.id,
        selectedProvince.code,
        selectedCity.name
      );
      setRows(data);
    };

    getPyns();
  }, [companyState.id, firebaseState, selectedCity, selectedProvince.code]);

  const handleHelpClick = () => {
    if (selectedDataSet !== "age") {
      setLegendOpen(true);
    }
  };

  const procTotals = (totals) => {
    let temp = {
      drivingBy: totals.drivingBy,
      enteringLocation: totals.enteringLocation,
      avgVisitMin: totals.avgVisitMin,
    };
    setUserStats(temp);
  };

  const procData = (obj) => {
    if (!obj) {
      return;
    }
    if (obj.error) {
      console.error(obj.error);
      eventStats.current = { events: [] };
    }
    const key = obj["key"];

    switch (key) {
      case "ages": {
        const ageEvents = obj[key];
        ageStats.current = {
          ageList: ageEvents.agesList,
        };
        break;
      }
      case "genders": {
        const genEvents = obj[key];
        genStats.current = {
          genList: genEvents.gendersList,
        };
        break;
      }
      case "agegenders": {
        const ageGenEvents = obj[key];
        ageGenStats.current = {
          ageGenStats: ageGenEvents.agegendersList,
        };
        break;
      }
      case "dateevents": {
        const dateEvents = obj[key];
        eventStats.current = {
          events: dateEvents.dateeventsList,
        };
        break;
      }
      case "pyndetails": {
        const pynEvents = obj[key];
        pynDetails.current = {
          pynsActInRange: pynEvents.actRangeTotal,
          pynsActivatedTotal: pynEvents.actTotal,
          avgColPynsMonthly: pynEvents.colAvgMonthly,
          avgColPynsWeekly: pynEvents.colAvgWeekly,
          pynsCollInRange: pynEvents.colRangeTotal,
          pynsCollectedTotal: pynEvents.colTotal,
        };
        break;
      }
      default:
        break;
    }
  };

  const setFormattedRange = (range) => {
    setDateRange(range);
    setFormattedDates(utils.formatDate(range[0]));
  };
  useGetTotals(
    currentUser.authUser,
    companyState,
    formattedDates,
    selectedLocation,
    procTotals,
    selectedProvince,
    selectedCity
  );

  useReporting(
    currentUser.authUser,
    companyState,
    formattedDates,
    selectedLocation,
    setReportingLoading,
    procData,
    selectedProvince,
    selectedCity,
    selectedEventType
  );

  const handleDownload = () => {
    setChoiceOpen(false);
    setConfirmOpen(true);
  };

  const handleDataChange = () => {
    setIsPercent(!isPercent);
  };

  useEffect(() => {
    setSelectedProvince(defaultState);
    setSelectedCity(defaultCity);
    setSelectedLocation(defaultPyn);
  }, [companyState]);

  return (
    <div>
      <Container maxWidth="false" sx={containerStyle}>
        <Box sx={titleBoxStyle}>
          <PageSubHeader
            pageTitle={
              mobileView ? constants.COMPANY_NAME : constants.PAGE_TITLE
            }
          ></PageSubHeader>
          <Box sx={subtitleBoxStyle}>
            <Typography variant="body2" color="primary" sx={descriptionStyle}>
              {mobileView ? constants.DB_DESC_M : constants.DB_DESC}
            </Typography>
          </Box>
        </Box>
        <Box sx={filterBorderStyle} />
        <FilterExpansion
          direction="r"
          doesWrap="true"
          pageTitle={constants.DB_FILTER_OPTIONS}
          pageContents={constants.DB_FILTER_OPTIONS_DESC}
          dashOverride={dashOverride}
        >
          <Box sx={mobileView | tabletView ? FOStylingMobile : FOStylingNorm}>
            <DropdownCheckbox
              inputLabel={
                companyState?.country === "CA"
                  ? constants.DB_DDL_PROVINCES
                  : constants.DB_DDL_STATES
              }
              type="prov"
              selection={selectedProvince}
              setSelection={setSelectedProvince}
              data={states}
              disabled={!states}
              emptyTitle={`Loading ${
                companyState?.country === "CA"
                  ? constants.DB_DDL_PROVINCES
                  : constants.DB_DDL_STATES
              }`}
            />
            <DropdownCheckbox
              inputLabel={constants.DB_DDL_CITIES}
              type="city"
              selection={selectedCity}
              setSelection={setSelectedCity}
              upperVar={selectedProvince.name}
              data={cities}
              disabled={!cities}
              emptyTitle={"Loading Cities"}
            />
            <DropdownCheckbox
              inputLabel={constants.DB_DDL_LOCATIONS}
              type="location"
              selection={selectedLocation}
              setSelection={setSelectedLocation}
              upperVar={selectedCity.name}
              data={rows}
              disabled={!rows}
              emptyTitle={"Loading Pyn Locations"}
            />
            <SingleCheckSelect
              options={eventTogglers}
              dropdownTitle={constants.DB_DDL_EVENT_TYPE}
              onUpdate={setSelectedEventType}
              inputLabel={constants.DB_DDL_EVENT_TYPE_ALL}
            />
            <DateRange
              range={dateRange}
              setRange={setFormattedRange}
              setIsSame={setSameDate}
            />
          </Box>
        </FilterExpansion>
        <Box sx={filterBorderStyle} />
        <Box sx={spacingBoxStyle} />
        <Typography variant="h2" color="primary">
          {constants.DB_VIEW_RESULTS_BELOW}
        </Typography>
        <Box sx={reBoxContainerStyle}>
          <ReBox
            width={tabletView || mobileView ? tabletWidth : statWidth}
            title={
              selectedProvince.code !== "" && companyState?.country === "CA"
                ? constants.DB_USER_STATS_PROVINCIAL
                : selectedProvince.code !== "" && companyState?.country === "US"
                ? constants.DB_USER_STATS_STATE
                : constants.DB_USER_STATS_NATIONAL
            }
            desc={constants.DB_USER_STATS_DESC}
            data={CSVTotals}
          >
            <StatDisplay
              textType="body1"
              border={true}
              tbPadding={tbpadding}
              statName={constants.DB_USER_STATS_DRIVING_BY}
              statNumber={userStats.drivingBy}
              loading={reportingLoading}
            />
            <StatDisplay
              textType="body1"
              border={true}
              tbPadding={tbpadding}
              statName={constants.DB_USER_STATS_ENTERING_LOCATION}
              statNumber={userStats.enteringLocation}
              loading={reportingLoading}
            />
            <StatDisplay
              textType="body1"
              tbPadding={tbpadding}
              statName={constants.DB_USER_STATS_AVG_VISIT}
              statNumber={userStats.avgVisitMin}
              loading={reportingLoading}
            />
          </ReBox>
          {/*Is the date range picked the same? */}
          {sameDate ? (
            <ReBox width={tabletView || mobileView ? tabletWidth : graphWidth}>
              <Typography
                variant="subtitle1"
                sx={{ textAlign: "center", m: "auto" }}
              >
                {constants.DB_NO_LINE_DATA}
              </Typography>
            </ReBox>
          ) : (
            <ReBox
              title={
                selectedEventType === "db"
                  ? constants.DB_USER_STATS_GRAPH_TITLE_DB
                  : selectedEventType === "en"
                  ? constants.DB_USER_STATS_GRAPH_TITLE_EN
                  : constants.DB_USER_STATS_GRAPH_TITLE_ALL
              }
              width={tabletView || mobileView ? tabletWidth : graphWidth}
              desc={constants.DB_USER_STATS_DESC}
              data={CSVEvents}
            >
              <LineGraph
                data={eventStats.current}
                type={selectedEventType}
                loading={reportingLoading}
                mobileView={mobileView}
                dateRange={dateRange}
              />
            </ReBox>
          )}
          {/* <PynBox
            csvPyns={CSVPyns}
            pynDetails={pynDetails}
            loading={reportingLoading}
          /> */}
          <ReBox
            width={"100%"}
            title={constants.DB_DEM_TITLE}
            desc={constants.DB_DEM_DESC}
            data={CSVDemographics}
          >
            {/* <DataFormatToggler onUpdate={setActiveDataType} /> */}
            {mobileView ? (
              <>
                <SingleCheckSelect
                  options={mobileBarGraphToggle}
                  dropdownTitle={constants.DB_BG_MOBILE_TOGGLE}
                  onUpdate={setSelectedDataSet}
                  widthOverride={"60%"}
                  marginOverride={"3%"}
                  mod={true}
                  inputLabel={constants.DB_BG_TITLE_1}
                />
                <HelpOutlineIcon sx={iconStyle} onClick={handleHelpClick} />
                <LegendDialog isOpen={legendOpen} setIsOpen={setLegendOpen} />
                <BarGraph
                  graphTitle={""}
                  data={
                    selectedDataSet === "age"
                      ? ageStats.current
                      : selectedDataSet === "gen"
                      ? genStats.current
                      : ageGenStats.current
                  }
                  kind={
                    selectedDataSet === "age"
                      ? "age"
                      : selectedDataSet === "gen"
                      ? "gender"
                      : "ageGender"
                  }
                  loading={reportingLoading}
                  eventType={selectedEventType}
                  formatToggle={activeDataType}
                  mobileView={mobileView}
                  isPercent={isPercent}
                />
              </>
            ) : (
              <>
                <Button
                  sx={percentBtnStyle}
                  onClick={handleDataChange}
                  variant={isPercent ? "outlined" : "contained"}
                  color="info"
                >
                  <Typography variant="h2" color={isPercent ? "info" : "#FFF"}>
                    {isPercent
                      ? constants.DB_SWITCH_NUM
                      : constants.DB_SWITCH_PERCENT}
                  </Typography>
                </Button>
                <BarGraph
                  graphTitle={constants.DB_BG_TITLE_1}
                  data={ageGenStats.current}
                  kind="ageGender"
                  loading={reportingLoading}
                  eventType={selectedEventType}
                  formatToggle={activeDataType}
                  isPercent={isPercent}
                />
                <BarGraph
                  graphTitle={constants.DB_BG_TITLE_2}
                  data={ageStats.current}
                  kind="age"
                  loading={reportingLoading}
                  eventType={selectedEventType}
                  formatToggle={activeDataType}
                  isPercent={isPercent}
                />
                <BarGraph
                  graphTitle={constants.DB_BG_TITLE_3}
                  data={genStats.current}
                  kind="gender"
                  loading={reportingLoading}
                  eventType={selectedEventType}
                  formatToggle={activeDataType}
                  isPercent={isPercent}
                />
              </>
            )}
          </ReBox>
          <Box sx={bottomBoxStyle}>
            <Typography variant="body2" color="primary">
              {constants.DB_DOWNLOAD}
            </Typography>
            {allCSVData && (
              <Button sx={buttonStyle} onClick={() => setChoiceOpen(true)}>
                <DownloadIcon sx={downloadIconStyle} />
              </Button>
            )}
          </Box>
          <DLChoiceModal
            open={choiceOpen}
            noHandler={() => setChoiceOpen(false)}
            yesHandler={handleDownload}
            data={allCSVData}
          />
          <DLConfirmModal
            text={constants.DB_DOWNLOAD_SUCCESS}
            open={confirmOpen}
            clickHandler={() => setConfirmOpen(false)}
          />
        </Box>
      </Container>
    </div>
  );
};
export default Dashboard;
