const CSVData = () => {
  const getCSVTotals = (userStats, formattedDates) => {
    return {
      headers: [
        { label: "Driving By", key: "drivingBy" },
        { label: "Entering Location", key: "enteringLocation" },
        { label: "Avg Visit (Min)", key: "avgVisitMin" },
      ],
      totals: [
        {
          drivingBy: userStats.drivingBy,
          enteringLocation: userStats.enteringLocation,
          avgVisitMin: userStats.avgVisitMin,
        },
      ],
      filename: `${formattedDates.start}-${formattedDates.endCSV}-totals.csv`,
    };
  };

  const getCSVEvents = (eventStats, formattedDates) => {
    let data;
    eventStats.current.events
      ? (data = eventStats.current.events.map((event) => ({
          total: event.total,
          eventTypeStats:
            event.eventType === "driving_by"
              ? "Driving By"
              : "Entering Location",
          date: event.date,
        })))
      : (data = [{}]);
    return {
      headers: [
        { label: "Date", key: "date" },
        { label: "Event Type", key: "eventTypeStats" },
        { label: "Total", key: "total" },
      ],
      totals: data,
      filename: `${formattedDates.start}-${formattedDates.endCSV}-events.csv`,
    };
  };

  const getCSVDemographics = (ageStats, genStats, AGStats, formattedDates) => {
    let ageData;
    let genData;
    let AGData;

    ageStats?.current.ageList
      ? (ageData = ageStats.current.ageList.map((list) => ({
          gender: "All",
          eventType:
            list.eventType === "driving_by"
              ? "Driving By"
              : list.eventType === "enter_location"
              ? "Entering Location"
              : "Driving By / Entering",
          cohort1420: list.cohort1420,
          cohort2130: list.cohort2130,
          cohort3140: list.cohort3140,
          cohort4150: list.cohort4150,
          cohort5164: list.cohort5164,
          cohort65: list.cohort65,
        })))
      : (ageData = [{}]);

    genStats?.current.genList
      ? (genData = genStats.current.genList.map((list) => ({
          gender: "All",
          eventType:
            list.eventType === "driving_by"
              ? "Driving By"
              : list.eventType === "enter_location"
              ? "Entering Location"
              : "Driving By / Entering",
          female: list.female,
          male: list.male,
          nonBinary: list.nonBinary,
          other: list.other,
          unspec: list.unspec,
          total: list.total,
        })))
      : (genData = [{}]);

    AGStats?.current.ageGenStats
      ? (AGData = AGStats.current.ageGenStats.map((list) => ({
          gender: list.gender === "" ? "Prefer Not to Say" : list.gender,
          eventType:
            list.eventType === "driving_by"
              ? "Driving By"
              : list.eventType === "enter_location"
              ? "Entering Location"
              : "Driving By / Entering",
          cohort1420: list.cohort1420,
          cohort2130: list.cohort2130,
          cohort3140: list.cohort3140,
          cohort4150: list.cohort4150,
          cohort5164: list.cohort5164,
          cohort65: list.cohort65,
        })))
      : (AGData = [{}]);

    return {
      headers: [
        { label: "Gender", key: "gender" },
        { label: "Event Type", key: "eventType" },
        { label: "Ages: 14-20", key: "cohort1420" },
        { label: "Ages: 21-30", key: "cohort2130" },
        { label: "Ages: 31-40", key: "cohort3140" },
        { label: "Ages: 41-50", key: "cohort4150" },
        { label: "Ages: 51-64", key: "cohort5164" },
        { label: "Ages: 65+", key: "cohort65" },
        { label: "Female", key: "female" },
        { label: "Male", key: "male" },
        { label: "Non-Binary", key: "nonBinary" },
        { label: "Other", key: "other" },
        { label: "Unspecified", key: "unspec" },
        { label: "Total", key: "total" },
      ],
      totals: [...genData, ...ageData, ...AGData],
      filename: `${formattedDates.start}-${formattedDates.endCSV}-demographics.csv`,
    };
  };

  const getCSVPynTotals = (pynTotals, formattedDates) => {
    return {
      headers: [
        { label: "Results Affected By Time Range =>", key: "affectedByTime" },
        { label: "# of Pyns Activated in Time Range", key: "pynsActInRange" },
        { label: "# of Pyns Collected in Time Range", key: "pynsCollInRange" },
        {
          label: "Results Not Affected By Date Range Selection =>",
          key: "notAffectedByTime",
        },
        {
          label: "Average # of Pyns Collected Monthly",
          key: "avgColPynsMonthly",
        },
        {
          label: "Average # of Pyns Collected Weekly",
          key: "avgColPynsWeekly",
        },
        { label: "Total # of Pyns Activated", key: "pynsActTotal" },
        { label: "Total # of Pyns Collected", key: "pynsColTotal" },
      ],
      totals: [
        {
          affectedByTime: "",
          pynsActInRange: pynTotals.current.pynsActInRange,
          pynsCollInRange: pynTotals.current.pynsCollInRange,
          notAffectedByTime: "",
          avgColPynsMonthly: pynTotals.current.avgColPynsMonthly,
          avgColPynsWeekly: pynTotals.current.avgColPynsWeekly,
          pynsActTotal: pynTotals.current.pynsActivatedTotal,
          pynsColTotal: pynTotals.current.pynsCollectedTotal,
        },
      ],
      filename: `${formattedDates.start}-${formattedDates.endCSV}-pynDetails.csv`,
    };
  };

  const getAllCSVData = (
    userStats,
    eventStats,
    pynTotals,
    ageStats,
    genStats,
    AGStats,
    formattedDates
  ) => {
    const locations = getCSVTotals(userStats, formattedDates);
    const events = getCSVEvents(eventStats, formattedDates);
    // const pyns = getCSVPynTotals(pynTotals, formattedDates);
    const demos = getCSVDemographics(
      ageStats,
      genStats,
      AGStats,
      formattedDates
    );

    demos.headers.splice(demos.headers.length - 1, 1);

    return {
      headers: [
        ...locations.headers,
        ...demos.headers,
        ...events.headers,
        // ...pyns.headers,
      ],
      totals: [
        ...locations.totals,
        ...demos.totals,
        ...events.totals,
        // ...pyns.headers,
      ],
      filename: `${formattedDates.start}-${formattedDates.endCSV}-allData.csv`,
    };
  };

  return {
    getCSVTotals,
    getCSVEvents,
    getCSVDemographics,
    getCSVPynTotals,
    getAllCSVData,
  };
};

export default CSVData;
