import { createContext, useEffect, useState } from "react";

const CURRENT_USER_INITIAL_STATE = {
  profile: null,
  company: null,
  authUser: null,
};

export const GlobalContext = createContext({});

export const GlobalProvider = (props) => {
  const [firebaseState] = useState(props.fsvc);
  const [companyState, setCompanyState] = useState();
  const [optionState, setOptionState] = useState("dashboard");
  const [linking, setLinking] = useState(false);
  const [linkClaim, setLinkClaim] = useState(false);
  const [signError, setSignError] = useState("");
  const [signLoad, setSignLoad] = useState(false);
  const [signUp, setSignUp] = useState(true);
  const [currentUser, setCurrentUser] = useState(CURRENT_USER_INITIAL_STATE);

  useEffect(() => {
    const { company } = currentUser;

    setCompanyState({
      ...company,
      country: company?.countryCode,
      id: company?.id || company?.uid,
      name: company?.companyName,
    });
  }, [currentUser]);

  return (
    <GlobalContext.Provider
      value={{
        firebaseState,
        optionState,
        setOptionState,
        linking,
        setLinking,
        signError,
        setSignError,
        signLoad,
        setSignLoad,
        signUp,
        setSignUp,
        linkClaim,
        setLinkClaim,
        currentUser,
        setCurrentUser,
        companyState,
        setCompanyState,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
