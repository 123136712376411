import { createTheme } from "@mui/material/styles";

const getCustomMuiTheme = () => {
  const customTheme = createTheme({
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            "&:hover:not(.Mui-disabled)": {
              cursor: "pointer",
            },
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            color: "#2E117F",
            fontFamily: "Karla",
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            borderRadius: "10px",
            marginTop: "1rem",
            width: "92%",
            color: "#2E117F",
            fontFamily: "Karla",
            padding: "1rem 0",
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            backgroundColor: "#fff",
            borderRadius: "10px",
            boxShadow: "-5px 5px 6px 1px #E6E6E6",
            width: "92%",
          },
          option: {
            color: "#2E117F",
            fontFamily: "Karla",
            fontSize: "1.2rem",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: "bolder",
            minWidth: "0",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "#2E117F",
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            display: "flex",
            flexDirection: "column",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: "#2E117F",
            fontFamily: "Karla",
            fontSize: "1.2rem",
          },
          notchedOutline: {
            borderColor: "#2E117F",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            color: "#2E117F",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            color: "#2E117F",
            fontFamily: "Karla",
            fontSize: "1rem",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "#2E117F",
            transform: "scale(1.5)",
            "&:hover:not(.Mui-disabled)": {
              cursor: "pointer",
            },
          },
        },
      },
    },
    palette: {
      type: "light",
      primary: {
        main: "#2E117F",
      },
      secondary: {
        main: "#C31CF3",
      },
      info: {
        main: "#207DF9",
      },
      success: {
        main: "#0A6C0A",
      },
      focused: {
        main: "#c41bf3",
      },
      error: {
        main: "#FF0000",
      },
      darkPurple: {
        main: "#5315BC",
      },
      lightPurple: {
        main: "#9B57EC",
      },
    },
    typography: {
      fontFamily: "Montserrat",
      h1: {
        fontSize: "1.8rem",
        fontWeight: 1100,
        "@media (max-width:1280px) and (min-width:744px)": {
          fontSize: "1.5rem",
        },
        "@media (max-width:743px) and (min-width:300px)": {
          fontSize: "1.25rem",
        },
      },
      h2: {
        fontSize: "1.563rem",
        fontWeight: 1100,
        "@media (max-width:1280px) and (min-width:744px)": {
          fontSize: "1.25rem",
        },
        "@media (max-width:743px) and (min-width:300px)": {
          fontSize: "1.15rem",
        },
      },
      h3: {
        fontSize: "1.15rem",
        fontWeight: 500,
        "@media (max-width:1280px) and (min-width:744px)": {
          fontSize: "0.75rem",
        },
        "@media (max-width:743px) and (min-width:300px)": {
          fontSize: "0.70rem",
        },
      },
      h4: {
        fontSize: "0.75rem",
        fontWeight: 500,
        lineHeight: 1,
        "@media (max-width:1280px) and (min-width:744px)": {
          fontSize: "0.70rem",
          lineHeight: 1,
        },
        "@media (max-width:743px) and (min-width:300px)": {
          fontSize: "0.65rem",
          lineHeight: 1,
        },
      },
      body1: {
        fontFamily: "Karla",
        fontSize: "1.1rem",
        "@media (max-width:1280px) and (min-width:744px)": {
          fontSize: "1rem",
        },
        "@media (max-width:743px) and (min-width:300px)": {
          fontSize: "0.9rem",
        },
      },
      body2: {
        fontFamily: "Karla",
        fontSize: "0.95rem",
        "@media (max-width:1280px) and (min-width:744px)": {
          fontSize: "0.90rem",
        },
        "@media (max-width:743px) and (min-width:300px)": {
          fontSize: "0.85rem",
        },
      },
      subtitle1: {
        color: "#2E117F",
        fontFamily: "Montserrat",
        fontSize: "1rem",
        fontWeight: 500,
        "@media (max-width:1280px) and (min-width:744px)": {
          fontSize: "0.80rem",
        },
        "@media (max-width:743px) and (min-width:300px)": {
          fontSize: "0.70rem",
        },
      },
      subtitle2: {
        fontFamily: "Karla",
        fontSize: "1rem",
        fontStyle: "italic",
        "@media (max-width:1280px) and (min-width:744px)": {
          fontSize: "0.85rem",
        },
        "@media (max-width:743px) and (min-width:300px)": {
          fontSize: "0.70rem",
        },
      },
      error: {
        color: "red",
        fontFamily: "Karla",
        fontSize: ".75rem",
      },
      error2: {
        color: "red",
        fontFamily: "Karla",
        fontSize: "1.5rem",
      },
      success: {
        color: "green",
        fontFamily: "Karla",
        fontSize: "1rem",
      },
      success2: {
        color: "green",
        fontFamily: "Karla",
        fontSize: "1.5rem",
      },
    },
  });

  return customTheme;
};

export default getCustomMuiTheme;
