import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const ComapanySelectionMenu = ({
  handleOnChange,
  listOptions,
  selectedValue,
  name,
  placeholder,
  width,
}) => {
  const onChangeHandler = (e, value) => {
    handleOnChange(value);
  };

  return (
    <div>
      <Autocomplete
        id={name}
        sx={{ width: width, mt: "2rem" }}
        options={listOptions}
        value={selectedValue}
        disablePortal
        onChange={onChangeHandler}
        autoHighlight
        getOptionLabel={(option) => option.companyName}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 1, flexShrink: 0 } }}
            {...props}
          >
            <img loading="lazy" width="20" src={`${option.iconUrl}`} alt="" />
            {option.companyName}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password",
            }}
          />
        )}
        ListboxProps={{
          style: {
            maxHeight: "11rem",
          },
        }}
      />
    </div>
  );
};

export default ComapanySelectionMenu;
